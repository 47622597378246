import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { connect } from 'react-redux';
import { compose, branch, renderNothing } from 'recompose';
import { withRouter } from 'react-router-dom';
import { isDevelopmentMode } from '../helpers/main';
import { UserService } from '../services/user-servcie';

const Component = React.memo(({ isActive }) => {
  useEffect(() => {
    const {
      GOOGLE_TAGS: { gtmIdDev, gtmIdProd }
    } = window.CONFIG;

    TagManager.initialize({
      gtmId: isDevelopmentMode ? gtmIdDev : gtmIdProd
    });
  }, []);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        gtm_consent: isActive ? 'TRUE' : 'FALSE',
        event: isActive ? 'gtm.optin' : 'gtm.optout'
      }
    });
  }, [isActive]);
});

export const GoogleTagManager = compose(
  branch(() => {
    const {
      GOOGLE_TAGS: { gtm: isGTMEnabled }
    } = window.CONFIG;

    return !isGTMEnabled;
  }, renderNothing),
  withRouter,
  connect(state => ({
    isActive: !UserService.hasAccount() && state.userAttributes.consents?.tracking
  }))
)(Component);
