import React from 'react';
import uuid from 'uuid';
import styles from '../../../../../../styles/components/layout-popup/managed.module.scss';
import { ConsentLists } from '../../../../../../pages/account/components';
import IntersectionVisible from '../../../../../intersection-visibility';
import { InputButtonWithIcon as ButtonWithIcon } from '../../../../../buttons/button-with-icon';
import { CoachMessage } from '../../../../../coach-message';
import { InputGroup } from '../../../../../inputs/input-group';
import { TBody } from '../../../../../template';
import { PurifiedHtmlContainer } from '../../../../purified-html-container';
import { Spacer } from '../../../../spacers';

export const ManagedState = React.memo(({ value, onAccept, onChange, onInputAppear }) => {
  const {
    ACCOUNT: {
      CONSENT_PAGE: {
        managed: {
          body,
          message,
          buttons: { confirm }
        }
      }
    }
  } = window.CONFIG;

  return (
    <>
      {message && (
        <>
          <CoachMessage>
            {[].concat(message).map(line => (
              <PurifiedHtmlContainer
                key={uuid('consent-managed-message')}
                renderAs='p'
                className='typography'
                html={line}
              />
            ))}
          </CoachMessage>
        </>
      )}
      {body && (
        <>
          <TBody html={body} />
          <Spacer renderAs='div' type='component' />
        </>
      )}
      <ConsentLists onChange={onChange} value={value} />
      <Spacer renderAs='div' type='component' />
      <IntersectionVisible onShow={onInputAppear} once>
        <InputGroup className={styles.wrapper}>
          <ButtonWithIcon className={styles.button} onClick={onAccept}>
            {confirm}
          </ButtonWithIcon>
        </InputGroup>
      </IntersectionVisible>
    </>
  );
});
