import React from 'react';
import { branch, compose, withProps } from 'recompose';
import { isUndefined } from '@packages/helpers/core/common';
import styles from '../../../../styles/components/question-input-field.module.scss';
import { ConfigService } from '../../../../services/config-service';
import { ButtonWithIcon } from '../../../../components/buttons/button-with-icon';

export const withContainer = Component => props =>
  (
    <div className={styles.container}>
      <div className={styles.controller}>
        <Component {...props} />
      </div>
    </div>
  );
export const withCompanionLayout = Component =>
  React.memo(({ buttonLabel, disabled, onSubmit, label, ...props }) => (
    <div className={styles.wrapper}>
      <div className={styles.input}>
        <Component {...props} />
      </div>
      <ButtonWithIcon buttonType='submit' aria-label={label} disabled={disabled} companion onClick={onSubmit} />
    </div>
  ));
export const withButtonLayout = Component =>
  React.memo(({ buttonLabel, disabled, onSubmit, ...props }) => (
    <>
      <Component {...props} />
      <div className={styles.button}>
        <ButtonWithIcon buttonType='submit' aria-label={buttonLabel} disabled={disabled} onClick={onSubmit}>
          {buttonLabel}
        </ButtonWithIcon>
      </div>
    </>
  ));

const isSubmittable = ({ onSubmit }) => onSubmit;

const mapDisabled = withProps(({ error, value, disabled, loading }) => ({
  disabled: Boolean(error) || isUndefined(value) || disabled || loading
}));
const withButtonLabel = withProps(({ buttonLabel }) => ({
  buttonLabel: buttonLabel ?? ConfigService.get('COACH.answer.nextButton.labelText')
}));

export const withButton = branch(isSubmittable, compose(mapDisabled, withButtonLabel, withButtonLayout));
export const withCompanion = branch(isSubmittable, compose(mapDisabled, withButtonLabel, withCompanionLayout));

const isCompanion = () => ConfigService.get('COACH.answer.nextButton.companion', false);

export const withSubmit = branch(isCompanion, withCompanion, withButton);
