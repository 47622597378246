import { branch, compose } from 'recompose';
import { withEmbeddedApp } from './with-embedded-app';
import { withDefaultApp } from './with-default-app';
import { withEmbeddedContext } from './with-embedded-context';

export const withApp = compose(
  withEmbeddedContext,
  branch(({ isEmbedded }) => isEmbedded, withEmbeddedApp),
  branch(({ isEmbedded }) => !isEmbedded, withDefaultApp)
);
