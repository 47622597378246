import { HeaderIcon } from '@apps/firsthomecoach/src/components/layout/application-header/default';
import { isVisible } from '@apps/firsthomecoach/src/components/layout/with-layout';
import { BREAKPOINTS_MATCHES } from '@apps/firsthomecoach/src/components/with-resize';
import * as NAVIGATION from '@apps/firsthomecoach/src/helpers/navigation';
import { useFavorite } from '@apps/firsthomecoach/src/helpers/status-data/use-favorite';
import React from 'react';
import { branch, compose, renderNothing, withProps } from 'recompose';
import { ConfigService } from '../../../../services/config-service';

const Component = ({ ownKey, slug, type, id, title }) => {
  const { iconName, handleAction } = useFavorite({ ownKey, slug, type, id, title });

  return <HeaderIcon name={iconName} onClick={handleAction} />;
};

export const FavoriteButton = compose(
  //TODO: used on story, vm article, default post-pages (blog, articles, projects) - discuss if needed a separate configuration
  withProps(() => ({ isDisplayFavoriteButton: ConfigService.get('DISPLAY_COMPONENTS.TOPBAR_SAVE_BUTTON', false) })),
  branch(({ isDisplayFavoriteButton }) => !isDisplayFavoriteButton, renderNothing),
  isVisible({
    onBreakpoints: Object.values(BREAKPOINTS_MATCHES),
    forTemplates: [NAVIGATION.STORY, NAVIGATION.ARTICLE, NAVIGATION.BLOG, NAVIGATION.BLOG_POST]
  })
)(Component);
