import React from 'react';
import { helpers } from '../../../api/helpers';
import { withAutoCompleteHandlers } from './components/autocomplete/handlers';
import { AutocompleteInput } from './components/autocomplete';

const Input = withAutoCompleteHandlers({
  valueName: 'formatted_address',
  userAttributeName: 'goal.goalsGeolocation',
  method: helpers.getGeocode
})(AutocompleteInput);

export const QuestionAddressInput = React.memo(
  ({ label, placeholder, value, onChange, onSubmit, loading, buttonLabel, explainer, isRequired }) => (
    <Input
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onSubmit={onSubmit}
      submitDisable={loading}
      buttonLabel={buttonLabel}
      explainer={explainer}
      isRequired={isRequired}
    />
  )
);
