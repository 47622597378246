import { ObjectShim } from '@packages/helpers/core/shims/object-shim';

export const valueToActiveState = inputValue => {
  if (!inputValue || !ObjectShim.isObject(inputValue) || ObjectShim.isEmpty(inputValue)) {
    return null;
  }

  const { checked, label } = Object.values(inputValue)[0];

  return checked ? label : null;
};
