import { compose, lifecycle, withHandlers } from 'recompose';
import { SECTION_FLOW_TYPE, STATIC_DATA_CHECKPOINT_IDENTIFIER } from '../../../helpers/chekpoint-page/constants';
import { evaluateCondition } from '../../../helpers/parcers/parce-condition';
import { withLoadingHandlers } from '../../../components/with-loader';
import { getNextSectionLink } from '../helpers/question-section';
import { withSectionData } from './with-section-data';

export const withQuestionSection = compose(
  withLoadingHandlers(false),
  withSectionData,
  withHandlers({
    skipSection:
      ({ question, history, userAttributes, plainStatuses, userData }) =>
      async () => {
        const { skipQuestion } = question;

        if (skipQuestion?.condition) {
          const skipSection = await evaluateCondition(
            { ...userAttributes, activity: plainStatuses, user: userData },
            skipQuestion.condition
          );

          if (skipSection) {
            history.replace(skipQuestion.nextPageLink);
            return true;
          }
        }

        return false;
      }
  }),
  withHandlers({
    getRedirectUrl:
      ({ sectionsParams, questionSection, activity, customDonePage, sendQuestionSectionInfo }) =>
      () => {
        const { flowType } = questionSection;

        // flow type is set on section
        if (flowType !== SECTION_FLOW_TYPE) {
          //reset isSectionEdition state to default
          sendQuestionSectionInfo({ isSectionEdition: false });

          //get next section link from sectionsParams by questionSection.endOfSection
          return getNextSectionLink({ sectionsParams, questionSection });
        }

        return customDonePage ?? `/checklist/${activity}/done`;
      },
    onSectionChange:
      ({ setCurrentSectionInfo, skipSection, turnOnLoading, turnOffLoading }) =>
      async () => {
        turnOnLoading();
        const isSkipped = await skipSection();

        setCurrentSectionInfo(isSkipped);

        if (!isSkipped) {
          turnOffLoading();
        }
      }
  }),
  lifecycle({
    async componentDidMount() {
      const { getStaticData, sectionsParams, setCurrentSectionInfo, currentQuestion } = this.props;

      //Fetch sectionsParams from Data Dictionary
      if (!sectionsParams.length) {
        await getStaticData(STATIC_DATA_CHECKPOINT_IDENTIFIER);
      }

      if (currentQuestion?.type === 'questionSectionPage') {
        setCurrentSectionInfo();
      }
    },
    async componentDidUpdate(prevProps) {
      const { currentQuestion, sectionsParams, currentSection, onSectionChange } = this.props;

      if (!sectionsParams.length || currentQuestion?.type !== 'questionSectionPage') {
        return;
      }

      // trigger on changing sections to set current not skipped section
      if (currentSection && prevProps.currentSection !== currentSection) {
        await onSectionChange();
      }
    }
  })
);
