import { branch, compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUserAttributes, sendApplicationData } from '../../../store/reducers/user-attributes/actions';
import { getValidations } from '../../../store/reducers/validations';
import { getPoints, getRewardCards } from '../../../store/reducers/points-and-awards';
import { getUserMarketingPreferences } from '../../../store/reducers/account/actions';
import { getScrollXPosition, getScrollYPosition, scrollTo } from '../../../helpers/layout/scroll';
import { withLoader } from '../../with-loader';
import { withEmbeddedContext } from '../with-embedded-context';
import { withDefaultContent } from './with-default-content';
import { withEmbeddedContent } from './with-embedded-content';

export const withContent = compose(
  connect(
    state => ({
      loading: state.userAttributes.loading
    }),
    {
      getUserAttributes,
      getValidations,
      getPoints,
      getRewardCards,
      getUserMarketingPreferences,
      sendApplicationData
    }
  ),
  withRouter,
  withEmbeddedContext,
  branch(({ isEmbedded }) => !isEmbedded, withDefaultContent),
  branch(({ isEmbedded }) => isEmbedded, withEmbeddedContent),
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        location: { pathname }
      } = this.props;

      const scrollX = getScrollXPosition();
      const scrollY = getScrollYPosition();
      const locationChanged = prevProps.location.pathname !== pathname;

      if (locationChanged && (scrollX || scrollY)) {
        scrollTo(window, { left: 0, top: 0 });
      }
    }
  }),
  withLoader
);
