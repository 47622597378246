import { parse, parseUrl, stringifyUrl } from 'query-string';
import { FunctionShim } from '@packages/helpers/core/shims/function-shim';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { EDIT_MODE } from './index';

export const exclude =
  (keys = []) =>
  params => {
    return ObjectShim.omit(params, keys);
  };

export const pick =
  (keys = []) =>
  params => {
    return ObjectShim.pick(params, keys);
  };

export const withQueryParams = (pathname = '', params = {}, transform) => {
  let { url, query } = parseUrl(pathname);

  query = { ...query, ...params };

  return stringifyUrl(
    {
      url,
      query: FunctionShim.isFunction(transform) ? transform(query) : query
    },
    { skipEmptyString: true }
  );
};

export const withSearchParams = (pathname, search = window.location.search, transform) => {
  return withQueryParams(pathname, parse(search), transform);
};

export const editMode = (pathname, mode = EDIT_MODE) => {
  return withQueryParams(pathname, { mode });
};

export const fromMode = (pathname, from = window.location.pathname) => {
  const [url, query] = pathname.split('?');

  const to = {
    pathname: url,
    state: { from }
  };

  if (query) {
    to.search = `?${query}`;
  }

  return {
    pathname: url,
    search: query,
    state: { from }
  };
};

export const getLocationFromModeState = (state = window.history.state?.state) => {
  return state?.from ?? null;
};

export const getLocationPropertyGoalState = (state = window.history.state?.state) => {
  return state?.fromPropertyGoalWidget ?? null;
};
