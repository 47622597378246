import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setNavThemedPages } from '../../store/reducers/navigation';
import { getUser } from '../../store/reducers/account/actions';
import { getMarketingLists } from '../../store/reducers/marketing-lists';
import { getConsentList } from '../../store/reducers/consent-list';
import { getMarketplacePartners } from '../../store/reducers/marketplace';
import { getAffiliateRelatedStaticData } from '../../store/reducers/static-data';
import { getStatusData } from '../../store/reducers/status-data';
import { getConfig } from '../../api/config';
import { NativeAction } from '../../services/native-action';
import { UserService } from '../../services/user-servcie';
import { ShadowUserService } from '../../services/shadow-user-service';
import { MarketingInfoService } from '../../services/marketing-info-serice';
import { getFeedbackAndFavorites } from '../../store/reducers/content-interaction';
import { extraPathnameSlash } from '../../helpers/regex';
import { withLoader } from '../with-loader';

export const withDefaultApp = compose(
  connect(null, {
    setNavThemedPages,
    getUser,
    getMarketingLists,
    getConsentList,
    getMarketplacePartners,
    getAffiliateRelatedStaticData,
    getStatusData,
    getFeedbackAndFavorites
  }),
  withState('loading', 'setLoading', true),
  withRouter,
  withHandlers({
    replacePathnameExtraSlash:
      ({ history }) =>
      location => {
        const { pathname } = location;

        if (extraPathnameSlash.test(pathname)) {
          // replace extra slash at the end of pathname
          const cleanLocation = { ...location, pathname: pathname.replace(extraPathnameSlash, '') };

          history.replace(cleanLocation);
        }
      }
  }),
  lifecycle({
    async componentDidMount() {
      const {
        setLoading,
        setNavThemedPages,
        getUser,
        getMarketingLists,
        getMarketplacePartners,
        history,
        location,
        getConsentList,
        getAffiliateRelatedStaticData,
        getStatusData,
        getFeedbackAndFavorites,
        replacePathnameExtraSlash
      } = this.props;

      replacePathnameExtraSlash(location);

      window.CONFIG = await getConfig();

      NativeAction.initialize();

      setNavThemedPages();

      await getAffiliateRelatedStaticData();

      if (!UserService.hasAccount()) {
        if (UserService.hasUser()) {
          await getUser();
        } else {
          await ShadowUserService.createIfNotExists(() => history.replace('/welcome/intro/start'));
        }

        await getStatusData();
      }

      await UserService.track();
      await getMarketingLists();
      await getConsentList(location);
      await getMarketplacePartners();
      await getFeedbackAndFavorites();

      setLoading(false);
    },
    componentDidUpdate(prevProps) {
      const { location, replacePathnameExtraSlash } = this.props;
      if (prevProps.location.pathname !== location.pathname) {
        replacePathnameExtraSlash(location.pathname);
      }

      MarketingInfoService.processURLAttributes(location.search);
    }
  }),
  withLoader
);
