import { compose, withHandlers } from 'recompose';
import { parse } from 'query-string';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { isNil, isUndefined } from '@packages/helpers/core/common';
import { connect } from 'react-redux';
import { changeLastPartOfPath } from '../../../helpers/utils';
import { GEO_LOCATION_FORMATTED_ADDRESS_ENGLAND } from '../../../helpers/constants';
import { fromMode, getLocationFromModeState, withSearchParams } from '../../../helpers/navigation/routing';
import { processAdditionalValues } from '../helpers/parse-additional-values';
import { users } from '../../../api/users';
import { withValue } from './with-value';
import { withQuestionState } from './with-question-state';

const mapHandlerByType = (type, event) => handlers => {
  const handler = handlers[type] ?? handlers.default;

  if (handler) return { [event]: handler };
  return {};
};

const withHelpers = withHandlers({
  goToNextQuestion:
    ({ history, location: { search, state }, data: { nextPageLink } }) =>
    () => {
      //Prevents skipping questions when user is going to edit results
      const origin = getLocationFromModeState(state);

      history.push(fromMode(withSearchParams(nextPageLink, search), origin));
    }
});

const withSubmitHandler = withHandlers(props => {
  const { onSubmit, turnOnLoading, turnOffLoading } = props;

  if (onSubmit) {
    return {
      onSubmit:
        ({ data, submitValue }) =>
        () =>
          onSubmit({ data, value: submitValue })
    };
  }

  const {
    type,
    history,
    location: { pathname, search },
    data: { nextPageLink }
  } = props;

  const onGoalsLocationSubmit = response => {
    const { goalsLocation, goalsLocationParsed = false } = response;

    const isEngland = goalsLocation === GEO_LOCATION_FORMATTED_ADDRESS_ENGLAND;

    if (isEngland) {
      return history.push(changeLastPartOfPath(pathname, '/region-england'));
    }

    const isParsed = goalsLocationParsed && Object.values(goalsLocationParsed).some(item => item);

    if (isParsed) {
      const mode = search && parse(search).mode;
      const query = mode ? `?mode=${mode}` : '';

      return history.push(nextPageLink + query);
    }

    history.push(changeLastPartOfPath(pathname, '/region'));
  };
  const onLocationSubmit = (response, key) => {
    // API Endpoint is aimed to always return next interface
    // [userAttributeName]: <Object>: <Location>
    // [${userAttributeName}LocationParsed]: <Object>: <ParsedLocation>
    const { [key]: location, [`${key}LocationParsed`]: locationParsed = false } = response;

    const isEngland = location?.formatted_address === GEO_LOCATION_FORMATTED_ADDRESS_ENGLAND;

    if (isEngland) {
      return history.push(changeLastPartOfPath(pathname, '/region-england'));
    }

    const isParsed = locationParsed && Object.values(locationParsed).some(item => item);

    if (isParsed) {
      const mode = search && parse(search).mode;
      const query = mode ? `?mode=${mode}` : '';

      return history.push(nextPageLink + query);
    }

    history.push(changeLastPartOfPath(pathname, '/region'));
  };

  return mapHandlerByType(
    type,
    'onSubmit'
  )({
    address:
      ({ submitValue, data, sendUserAttribute, attributeType, sendStatusUpdate }) =>
      async () => {
        const { userAttributeField } = data;
        turnOnLoading();

        const sendRequest = sendUserAttribute(attributeType);
        const response = await sendRequest(submitValue[attributeType]);

        sendStatusUpdate();

        // TODO: Refactor it on API endpoint readiness.
        return userAttributeField === 'goalsGeolocation'
          ? onGoalsLocationSubmit(response)
          : onLocationSubmit(response, userAttributeField);
      },
    button:
      ({
        identifier,
        data,
        sendUserAttribute,
        sendAdditionalUserAttributes,
        goToNextQuestion,
        userAttributes,
        attributeType,
        sendStatusUpdate
      }) =>
      async () => {
        const { userAttributeField, userAttributeValue, redirectAfterSave, additionalValues, staticPayload } = data;

        const updatedUserAttributes = {
          [userAttributeField]: userAttributeValue,
          ...staticPayload
        };

        turnOnLoading();

        const sendMain = () => {
          if (userAttributeField) {
            const sendRequest = sendUserAttribute(attributeType);

            return sendRequest(updatedUserAttributes);
          }
        };

        const sendAdditional = async () => {
          if (additionalValues) {
            const processedValues = await processAdditionalValues(
              ObjectShim.mergeDeep(userAttributes, { [attributeType]: updatedUserAttributes }),
              additionalValues,
              { identifier }
            );
            if (processedValues) {
              return sendAdditionalUserAttributes(ObjectShim.deepen(processedValues), true);
            }
          }
        };

        // redirectAfterSave is mandatory if next question relies on data sent on previous question
        // e.g. pension finder question currently-employed which is right before company-name
        // relies on result of prev question
        if (redirectAfterSave) {
          //waiting for server to respond and redirect after all endpoints are resolved
          await sendMain();
          await sendAdditional();
          await sendStatusUpdate();

          goToNextQuestion();
        } else {
          //not waiting for server response, optimistic UI approach
          sendMain();
          sendAdditional();
          sendStatusUpdate();

          goToNextQuestion();
        }
      },
    groupParamsMarketingList:
      ({
        identifier,
        submitValue,
        submitAdditionalValues,
        data,
        sendUserAttribute,
        sendUserGroupAttributes,
        sendAdditionalUserAttributes,
        goToNextQuestion,
        userAttributes,
        subscribeUnsubscribeMarketingList,
        sendStatusUpdate
      }) =>
      async () => {
        const { marketingListName } = data;

        const isOneType = Object.keys(submitValue).length === 1;

        turnOnLoading();

        const sendMain = () => {
          if (isOneType) {
            const [attributeType, data] = ObjectShim.entries(submitValue)[0];

            const sendRequest = sendUserAttribute(attributeType);
            return sendRequest(data);
          } else {
            return sendUserGroupAttributes(submitValue);
          }
        };

        const sendAdditional = async () => {
          if (submitAdditionalValues.length) {
            const processedValues = await processAdditionalValues(
              ObjectShim.mergeDeep(userAttributes, submitValue),
              submitAdditionalValues,
              { identifier }
            );
            if (processedValues) {
              return sendAdditionalUserAttributes(ObjectShim.deepen(processedValues), true);
            }
          }
        };

        const subscriptionMarketingList = async () => subscribeUnsubscribeMarketingList({ [marketingListName]: true });

        await sendMain();
        await sendAdditional();
        await sendStatusUpdate();

        await subscriptionMarketingList();

        goToNextQuestion();
      },
    introducer:
      ({ goToNextQuestion, submitValue, sendUserAttribute, sendStatusUpdate }) =>
      ({ setSubmitError, errorMessages, value }) =>
      async () => {
        turnOnLoading();

        const sendMain = () => {
          const [attributeType, data] = ObjectShim.entries(submitValue)[0];

          const sendRequest = sendUserAttribute(attributeType);
          return sendRequest(data);
        };

        const response = await users.updateUser({ introducerCode: value });

        if (!response.ok) {
          const error = await response.json();
          turnOffLoading();
          return setSubmitError(errorMessages?.request || error.message);
        }

        await sendMain();
        sendStatusUpdate();
        goToNextQuestion();
      },
    introducerAutocomplete:
      ({ goToNextQuestion, submitValue, sendUserAttribute, sendStatusUpdate }) =>
      async () => {
        turnOnLoading();

        const [attributeType, data] = ObjectShim.entries(submitValue)[0];
        const [attributeField, selectedItem] = ObjectShim.entries(data)[0];

        const sendMain = async () => {
          const sendRequest = sendUserAttribute(attributeType);
          return sendRequest({ [attributeField]: selectedItem.value, [`${attributeField}Name`]: selectedItem.name });
        };

        await users.updateUser({ introducerCode: selectedItem.key });

        await sendMain();
        sendStatusUpdate();
        goToNextQuestion();

        turnOffLoading();
      },
    default:
      ({
        identifier,
        submitValue,
        submitAdditionalValues,
        data,
        sendUserAttribute,
        sendUserGroupAttributes,
        sendAdditionalUserAttributes,
        goToNextQuestion,
        userAttributes,
        sendStatusUpdate
      }) =>
      async () => {
        const { redirectAfterSave } = data;

        const isOneType = Object.keys(submitValue).length === 1;

        turnOnLoading();

        const sendMain = () => {
          if (isOneType) {
            const entry = ObjectShim.entries(submitValue)[0];
            const [attributeType, data] = entry;

            const sendRequest = sendUserAttribute(attributeType);
            return sendRequest(data);
          } else {
            return sendUserGroupAttributes(submitValue);
          }
        };

        const sendAdditional = async () => {
          if (submitAdditionalValues.length) {
            const processedValues = await processAdditionalValues(
              ObjectShim.mergeDeep(userAttributes, submitValue),
              submitAdditionalValues,
              { identifier }
            );
            if (processedValues) {
              return sendAdditionalUserAttributes(ObjectShim.deepen(processedValues), true);
            }
          }
        };

        // redirectAfterSave is mandatory if next question relies on data sent on previous question
        // e.g. pension finder question currently-employed which is right before company-name
        // relies on result of prev question
        if (redirectAfterSave) {
          //waiting for server to respond and redirect after all endpoints are resolved
          await sendMain();
          await sendAdditional();
          await sendStatusUpdate();

          goToNextQuestion();
        } else {
          //not waiting for server response, optimistic UI approach
          sendMain();
          sendAdditional();
          sendStatusUpdate();

          goToNextQuestion();
        }
      }
  });
});

const withChangeHandler = withHandlers(props => {
  const { onChange } = props;

  if (onChange) {
    return {
      onChange:
        ({ data, value }) =>
        () =>
          onChange({ data, value })
    };
  }

  const { type, checkDisable } = props;

  return mapHandlerByType(
    type,
    'onChange'
  )({
    wheel:
      ({ setValue, setFormValue }) =>
      () =>
      value => {
        checkDisable(value);
        setFormValue(value);
        setValue(value);
      },
    checkbox:
      ({ value, setValue, setFormValue }) =>
      (name, boundValue) =>
      e => {
        const checkboxValue = { ...value, [name]: { checked: e ? e.target.checked : false, value: boundValue } };

        checkDisable(checkboxValue);
        setFormValue(checkboxValue);
        setValue(checkboxValue);
      },
    radio:
      ({ setValue, setFormValue }) =>
      ({ name, boundValue, usePlainValue }) =>
      e => {
        const fullValue = { [name]: { checked: e ? e.target.checked : false, value: boundValue } };
        const value = usePlainValue ? boundValue : fullValue;

        checkDisable(value);
        setFormValue(value);
        setValue(value);
      },
    segmentedButton:
      ({ setValue, setFormValue }) =>
      (boundValue, name, label) => {
        const value = { [name]: { checked: true, value: boundValue, label } };

        checkDisable(value);
        setFormValue(value);
        setValue(value);
      },
    select:
      ({ setValue, setFormValue }) =>
      () =>
      value => {
        checkDisable(value);
        setFormValue(value);
        setValue(value);
      },
    introducer:
      ({ setValue, setFormValue }) =>
      ({ setSubmitError }) =>
      value => {
        setSubmitError(null);
        setFormValue(value);
        checkDisable(value);
        setValue(value);
      },
    default:
      ({ setValue, setFormValue }) =>
      value => {
        checkDisable(value);
        setFormValue(value);
        setValue(value);
      }
  });
});

const withInputHandler = withHandlers(props => {
  const { onInput } = props;

  if (onInput) {
    return {
      onInput:
        ({ data, value }) =>
        () =>
          onInput({ data, value })
    };
  }

  const { type } = props;

  return mapHandlerByType(
    type,
    'onInput'
  )({
    checkbox:
      ({ value, setValue, setFormValue }) =>
      name =>
      inputValue => {
        const newVal = {
          ...value,
          [name]: {
            text: inputValue,
            checked: true
          }
        };
        setFormValue(newVal);
        setValue(newVal);
      },
    radio:
      ({ setValue, setFormValue }) =>
      name =>
      inputValue => {
        const newVal = {
          [name]: {
            text: inputValue,
            checked: true
          }
        };
        setFormValue(newVal);
        setValue(newVal);
      }
  });
});

const withDisableHandler = compose(
  withHandlers({
    setFormDisabledItem: ({ setFormDisabledItems, ...props }) => setFormDisabledItems(props)
  }),
  withHandlers(props => {
    const { type } = props;

    return mapHandlerByType(
      type,
      'checkDisable'
    )({
      checkbox:
        ({ setFormDisabledItem, requiredAll, items }) =>
        value => {
          if (isNil(value)) return setFormDisabledItem(true);

          const conditionDefault = !Object.values(value).some(item => item.checked);
          const conditionRequiredAll = !(
            Object.values(value).every(item => item.checked) &&
            items.every(item => Object.keys(value).includes(item.name))
          );

          const isDisabled = requiredAll ? conditionRequiredAll : conditionDefault;
          setFormDisabledItem(isDisabled);
        },
      radio:
        ({ setFormDisabledItem, items, plainValue }) =>
        value => {
          const isDisabledItem = plainValue
            ? isNil(value) || !items.some(item => item.value === value)
            : isNil(value) || !Object.values(value).some(item => item.checked);

          setFormDisabledItem(isDisabledItem);
        },
      segmentedButton:
        ({ setFormDisabledItem }) =>
        value => {
          setFormDisabledItem(isNil(value) || !Object.values(value).some(item => item.checked));
        },
      number:
        ({ setFormDisabledItem }) =>
        value => {
          setFormDisabledItem(isUndefined(value) || isNaN(value));
        },
      select:
        ({ setFormDisabledItem }) =>
        value => {
          setFormDisabledItem(!value);
        },
      default:
        ({ setFormDisabledItem }) =>
        value => {
          setFormDisabledItem(isUndefined(value));
        }
    });
  })
);

const withErrorHandler = compose(
  withHandlers({
    setFormErrorItem: ({ setFormErrorItems, ...props }) => setFormErrorItems(props)
  }),
  withHandlers(props => {
    const { type } = props;

    return mapHandlerByType(
      type,
      'onError'
    )({
      default:
        ({ setFormDisabledItem, setFormErrorItem }) =>
        error => {
          setFormErrorItem(error);
          setFormDisabledItem(!!error);
        }
    });
  })
);

export const withInputHandlers = compose(
  withQuestionState,
  withDisableHandler,
  withErrorHandler,
  withValue,
  withChangeHandler,
  withInputHandler
);

export const withSubmitHandlers = compose(
  connect(({ userAttributes }) => ({ userAttributes })),
  withHelpers,
  withSubmitHandler
);
