import { PromiseShim } from '@packages/helpers/core/shims/promise-shim';
import { evaluateCondition } from '../parcers/parce-condition';

const visiblePosts = posts => posts.filter(({ isVisible }) => isVisible);

export const filterKnowledgeBase = knowledgeBase =>
  knowledgeBase.reduce((acc, currTopic) => {
    const posts = visiblePosts(currTopic.posts);

    if (posts.length) {
      currTopic.posts = posts;

      acc.push(currTopic);
    }

    return acc;
  }, []);

export const checkTopicDisplayCondition = async (knowledgeBase, userState) => {
  const checkDisplayCondition = async (displayCondition, userState) => {
    if (!displayCondition) return true;

    return await evaluateCondition(userState, displayCondition);
  };

  const checkPostDisplayCondition = async posts =>
    await PromiseShim.all(
      posts.map(async ({ displayCondition, ...post }) => ({
        ...post,
        isVisible: await checkDisplayCondition(displayCondition, userState)
      }))
    );

  return await PromiseShim.all(
    knowledgeBase.map(async ({ posts, ...rest }) => ({
      ...rest,
      posts: await checkPostDisplayCondition(posts)
    }))
  );
};
