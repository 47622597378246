import React from 'react';
import styles from '../../styles/components/application-footer.module.scss';
import { ConfigService } from '../../services/config-service';
import { BARRIER, CHECKPOINT, NOT_FOUND, QUESTION, SECTION, TOUR } from '../../helpers/navigation';
import { LAYOUT_SECTIONS } from '../../helpers/layout/sections';
import { BREAKPOINTS_MATCHES } from '../with-resize';
import { Sticky } from './sticky';
import { NavigationBar } from './navigation';
import { isVisible, not, useLayoutSection } from './with-layout';

const FooterComponent = React.memo(({ className }) => {
  const ref = useLayoutSection(LAYOUT_SECTIONS.BOTTOM_NAVIGATION, 'clientHeight');

  const { buttons } = ConfigService.get('NAVIGATION_BAR', {});

  return (
    <Sticky ref={ref} renderAs='footer' className={className} nested={false} bottom>
      <div className={styles.container}>
        <nav className={styles.navigation}>
          <NavigationBar items={buttons} />
        </nav>
      </div>
    </Sticky>
  );
});

export const ApplicationFooter = isVisible({
  name: 'applicationFooter',
  forTemplates: [not(QUESTION), not(SECTION), not(TOUR), not(BARRIER), not(NOT_FOUND), not(CHECKPOINT)],
  onBreakpoints: [BREAKPOINTS_MATCHES.MD_AND_DOWN]
})(FooterComponent);
