import { isNil } from '@packages/helpers/core/common';
import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { branch, compose, renderComponent, withHandlers } from 'recompose';
import defaultProps from 'recompose/defaultProps';
import { ariaDisabledInterceptor } from '../../helpers/aria-disabled-interceptor';
import { omitProps } from '../../helpers/hocs/omit-props';
import { openLink } from '../../helpers/utils';
import { setRedirectionLink } from '../../store/reducers/navigation';
import { sendStatus } from '../../store/reducers/status-data';
import styles from '../../styles/components/button.module.scss';
import { AffiliateIcon } from '../icons/affiliate-icon';
import { withContainer } from '../inputs/input-field';
import { withCombinedAriaLabel } from '../screen-reader-hidden-text';
import { withHistory } from '../with-history';
import { getStatusPlain } from '../../helpers/custom-page/is-completed';

export const Button = React.memo(
  ({
    renderAs: Component = 'button',
    type = 'major',
    icon = <AffiliateIcon name='button-yes' />,
    buttonType,
    className,
    account,
    companion,
    children,
    isExternal,
    onClick,
    disabled,
    ...props
  }) => {
    return (
      <Component
        type={buttonType}
        className={classnames(styles.container, styles[type], { [styles.companion]: companion }, className)}
        aria-disabled={disabled}
        onClick={ariaDisabledInterceptor(onClick)}
        {...props}
      >
        {!companion && <div className={styles.text}>{children ?? 'Continue'}</div>}
        {icon && <div className={classnames(styles.icon, styles[account])}>{icon}</div>}
      </Component>
    );
  }
);

Button.displayName = 'Button';

const mapStateToProps = ({ statusData }, { activity, status }) => {
  const { parsedStatuses } = statusData;

  if (activity in parsedStatuses && getStatusPlain(parsedStatuses[activity]) === status) {
    return {
      status: null
    };
  }

  return {};
};

const CONSTRAINTS = [
  'to',
  'history',
  'activity',
  'status',
  'sendStatus',
  'setRedirectionLink',
  'onBeforeRoutePush',
  'handleClick',
  'ariaLabel'
];

const ButtonContainer = compose(
  defaultProps({
    buttonType: 'button'
  }),
  connect(mapStateToProps, { sendStatus, setRedirectionLink }),
  withHistory,
  withHandlers({
    handleClick: props => event => {
      const {
        to,
        history,
        isExternal = false,
        activity,
        status,
        sendStatus,
        setRedirectionLink,
        onBeforeRoutePush,
        buttonType,
        onClick
      } = props;

      if (onClick) {
        if (buttonType === 'submit') event.preventDefault();

        return onClick(event);
      }

      if (to) {
        if (buttonType === 'submit') event.preventDefault();
        if (activity && status) sendStatus(activity, status);
        if (to.pathname === '/barrier') setRedirectionLink(to.state.from);

        if (onBeforeRoutePush) {
          const fromCallback = onBeforeRoutePush(props);

          if (fromCallback !== void 0) return fromCallback;
        }

        return openLink(to, history, isExternal);
      }
    }
  }),
  omitProps(CONSTRAINTS, props => {
    const { handleClick, 'aria-label': label, companion, ariaLabel } = props;
    // Very awful code fragment. Unfortunately, its refactoring will affect the whole code base which is not desirable in the scope of the current ticket.
    // The Refactoring Ticket has been created and placed into the backlog. https://life-moments.atlassian.net/browse/LBS-56
    const companionLabel = companion ? label ?? 'Continue' : undefined;

    return {
      onClick: handleClick,
      'aria-label': ariaLabel || companionLabel
    };
  })
);

const FakeButton = props => <Button renderAs='div' {...props} />;
const LinkButton = React.memo(({ href, isExternal, ...props }) => (
  <Button renderAs='a' href={href} target={isExternal ? '_blank' : void 0} {...props} />
));

export const ButtonWithIcon = compose(
  withCombinedAriaLabel,
  branch(({ href }) => href, renderComponent(LinkButton)),
  branch(
    ({ onClick, to, buttonType }) => isNil(onClick) && isNil(to) && buttonType !== 'submit',
    renderComponent(FakeButton)
  ),
  ButtonContainer
)(Button);

ButtonWithIcon.displayName = 'ButtonWithIcon';

export const InputButtonWithIcon = withContainer(ButtonWithIcon);
