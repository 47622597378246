import { PromiseShim } from '@packages/helpers/core/shims/promise-shim';
import { configuration } from './configuration';

const CONFIGURATION_IDENTIFIER = 'configuration';

export const getConfig = async () => {
  const hourStamp = new Date().setHours(new Date().getHours(), 0, 0, 0);

  const thenHandler = response => {
    if (!response.ok) {
      return {};
    }

    return response.json();
  };

  const mainFetch = fetch(`/config/config.json?nocache=${hourStamp}`, {
    method: 'GET'
  }).then(thenHandler);

  const dataDictionaryFetch = configuration.getDataDictionary(CONFIGURATION_IDENTIFIER).then(thenHandler);

  return PromiseShim.all([mainFetch, dataDictionaryFetch]).then(response => {
    return response.reduce((prev, current) => ({ ...prev, ...current }), {});
  });
};
