// eslint-disable-next-line no-useless-escape
export const emailCheck =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const numberComma = /\B(?=(\d{3})+(?!\d))/g;

export const shareRoutesCheck = /\/start|blog\/|articles\/|lp\//;
export const formatMoneyRegex = /\B(?=(\d{3})+(?!\d))/g;
export const interpolateRegex = /\$\{([^}]+)\}/g;
export const phoneNumberRegex = /^([+]|[00]{2})([0-9]|[ -])*/;
export const starsRegex = /\*([^${}\s]*?)\*/g;
export const numericRegex = /[\D]/g;
export const extraPathnameSlash = /\/$/;
export const mabUserPhoneNumberRegex =
  /^(\(?0\d{2,4}\)?\s?\d{3,4}\s?\d{3,4})|(\(\d{2,4}\)?\s?\d{10,11})|(\+\d{2,4}\)?\s?\d{10,11})(\s?(\d{4}|\d{3}))?$/;
export const mabUserNameRegex = /^[a-zA-Z\u00C0-\u02B8']([a-zA-Z\u00C0-\u02B8\-\s']+)?[a-zA-Z\u00C0-\u02B8']$/;
export const mabUserNameApostrophesRegex = /^'(.*)'$/;
export const mabUserNameExcludedRegex = /÷+/;
export const mabUserNameRepeatedRegex = /[\s\-']{2,}/;
