export const wrap = (data, wrapper) => {
  switch (wrapper) {
    case 'link':
      return `<a href=${data.to} ${data.isExternal ? 'target="_blank"' : ''}>${data.label}</a>`;
    case 'strong':
      return `<strong>${data}</strong>`;
    case 'span':
      return `<span>${data}</span>`;
    case 'p':
      return `<p>${data}</p>`;
    default:
      return data;
  }
};

export const stringToWrappedText = (str, tag) =>
  str
    .split('\n')
    .filter(Boolean)
    .map(text => wrap(text, tag))
    .join('');
