import React, { useEffect, useState } from 'react';
import { parse } from 'query-string';
import { branch, compose, lifecycle, withProps, withState } from 'recompose';
import { connect } from 'react-redux';
import { users } from '../../../api/users';
import { NativeAction } from '../../../services/native-action';
import { CoachMessage } from '../../coach-message';
import { PurifiedHtmlContainer } from '../purified-html-container';
import { InputGroup } from '../../inputs/input-group';
import { InputButtonWithIcon as ButtonWithIcon } from '../../buttons/button-with-icon';
import { Modal } from '../../modal';
import { withLoader, withLoadingHandlers } from '../../with-loader';
import { withHistory } from '../../with-history';
import { parseClientSchemaIdParameter } from '../../../helpers/account';
import { getUser } from '../../../store/reducers/account/actions';

const ClientVerificationModalComponent = React.memo(({ error, children, getUser }) => {
  const [externalCode, setExternalCode] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const { externalCode } = await getUser();

        setExternalCode(externalCode);
      } catch {
        setExternalCode('Unable to get external id');
      }
    })();
  }, [getUser]);

  if (error) {
    const handleTrigger = trigger => () => {
      NativeAction.trigger(trigger);
    };

    const {
      COMPONENTS: {
        CLIENT_VERIFICATION_MODAL: { messages, buttons }
      }
    } = window.CONFIG;

    return (
      <Modal label='Client Verification Modal' show={Boolean(error)}>
        <CoachMessage>
          {[].concat(messages).map((line, index) => (
            <PurifiedHtmlContainer key={index} renderAs='p' className='typography' html={line} />
          ))}
          <p>Error Code : {externalCode}</p>
        </CoachMessage>
        <InputGroup type='coach'>
          {[].concat(buttons).map(({ type, label, trigger }, index) => (
            <ButtonWithIcon key={index} type={type} onClick={handleTrigger(trigger)}>
              {label}
            </ButtonWithIcon>
          ))}
        </InputGroup>
      </Modal>
    );
  }

  return children;
});

export const ClientVerificationModal = compose(
  connect(null, { getUser }),
  withHistory,
  withProps(({ history }) => {
    const { client_customer_id: clientCustomerId, client_scheme_id: clientSchemeId } = parse(history.location.search);

    return {
      clientCustomerId,
      clientSchemeId,
      hasToBeUpdated: Boolean(clientCustomerId) || Boolean(clientSchemeId)
    };
  }),
  withState('error', 'setError', null),
  withLoadingHandlers(({ hasToBeUpdated }) => hasToBeUpdated),
  branch(
    ({ hasToBeUpdated }) => hasToBeUpdated,
    lifecycle({
      async componentDidMount() {
        const { clientCustomerId, clientSchemeId, turnOffLoading } = this.props;

        try {
          const response = await users.updateUser({
            clientCustomerId,
            clientSchemeId: parseClientSchemaIdParameter(clientSchemeId)
          });

          if (!response.ok) {
            const { message } = await response.json();

            throw new Error(message);
          }
        } catch (error) {
          const { setError } = this.props;

          setError(error);
        } finally {
          turnOffLoading();
        }
      }
    })
  ),
  withLoader
)(ClientVerificationModalComponent);
