import { parse } from 'query-string';
import { DEVICE } from '../user-agent';

export const deviceDetection = () => {
  const { utm_medium } = parse(window.location.search);

  const device =
    (utm_medium === 'ios' ? 'IOS App' : false) ||
    (utm_medium === 'android' ? 'Android App' : false) ||
    (DEVICE.IS_MOBILE_BROWSER() ? 'Mobile Browser' : false) ||
    'Desktop';

  return device;
};
