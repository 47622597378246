import { compose, lifecycle, withState } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setNavThemedPages } from '../../store/reducers/navigation';
import { getUser } from '../../store/reducers/account/actions';
import { getAffiliateRelatedStaticData } from '../../store/reducers/static-data';
import { getStatusData } from '../../store/reducers/status-data';
import { getConfig } from '../../api/config';
import { NativeAction } from '../../services/native-action';
import { UserService } from '../../services/user-servcie';
import { ShadowUserService } from '../../services/shadow-user-service';
import { MarketingInfoService } from '../../services/marketing-info-serice';
import { getConsentList } from '../../store/reducers/consent-list';
import { withLoader } from '../with-loader';

export const withEmbeddedApp = compose(
  connect(null, {
    setNavThemedPages,
    getUser,
    getAffiliateRelatedStaticData,
    getStatusData,
    getConsentList
  }),
  withState('loading', 'setLoading', true),
  withRouter,
  lifecycle({
    async componentDidMount() {
      const {
        setLoading,
        setNavThemedPages,
        history,
        location,
        getAffiliateRelatedStaticData,
        getStatusData,
        getConsentList
      } = this.props;

      window.CONFIG = await getConfig();

      NativeAction.initialize();

      setNavThemedPages();

      await getConsentList(location);
      await getAffiliateRelatedStaticData();
      await ShadowUserService.createIfNotExists(() => history.replace('/welcome/intro/start'));
      await getStatusData();
      await UserService.track();

      setLoading(false);
    },
    componentDidUpdate() {
      const { location } = this.props;

      MarketingInfoService.processURLAttributes(location.search);
    }
  }),
  withLoader
);
