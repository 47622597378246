import { lifecycle, withHandlers } from 'recompose';

export const withFetchFAQData = lifecycle({
  async componentDidMount() {
    const { getFAQ, FAQGroupName } = this.props;

    //FAQGroupName is set in configuration file
    getFAQ({ name: FAQGroupName, normalize: 'key' });
  }
});

export const withShowFAQ = withHandlers({
  showInfo:
    ({ toggleModal, FAQGroupName }) =>
    infoFAQKey =>
      toggleModal(true, 'help', { faqName: FAQGroupName, faqIndex: infoFAQKey })
});
