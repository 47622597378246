import React from 'react';
import { LAYOUT_SECTIONS } from '../../../../helpers/layout/sections';
import { BARRIER, NOT_FOUND, QUESTION, SECTION, TOUR } from '../../../../helpers/navigation';
import { ConfigService } from '../../../../services/config-service';
import { BREAKPOINTS_MATCHES } from '../../../with-resize';
import { NavigationBar } from '../../navigation';
import { isVisible, not, useLayoutSection } from '../../with-layout';

const Component = ({ className }) => {
  const ref = useLayoutSection(LAYOUT_SECTIONS.TOP_SECTION_NAVIGATION);

  const { buttons } = ConfigService.get('NAVIGATION_BAR', {});

  return (
    <nav className={className}>
      <NavigationBar ref={ref} items={buttons} top />
    </nav>
  );
};

export const TopSectionNavigationBar = isVisible({
  forTemplates: [not(QUESTION), not(SECTION), not(TOUR), not(BARRIER), not(NOT_FOUND)],
  onBreakpoints: [BREAKPOINTS_MATCHES.LG_AND_UP],
  name: 'topNavigation'
})(Component);
