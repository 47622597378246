import { isNil } from '@packages/helpers/core/common';
import { formatMoneyRegex } from '../regex';

export const toMoneyStringWithK = value => {
  const currencySymbol = window.CONFIG.DEFAULT_VALUES.CURRENCY_SYMBOL;
  let currentValue = value;

  if (isNil(value) || isNaN(value)) {
    return 'Not defined';
  }

  if (typeof currentValue === 'string' && !isNaN(Number(value))) {
    currentValue = Number(value);
  }

  if (currentValue >= 1e6) {
    return currencySymbol + (currentValue / 1e6).toFixed(1) + 'm';
  }

  if (currentValue > 1e3) {
    return currencySymbol + (currentValue / 1e3).toFixed(0) + 'k';
  }

  return currencySymbol + currentValue.toFixed(0);
};

export const toMoneyStringWithComma = (value, input) => {
  const currencySymbol = window.CONFIG.DEFAULT_VALUES.CURRENCY_SYMBOL;

  if ((isNil(value) || isNaN(value)) && input) {
    return '';
  }

  return currencySymbol + value.toString().replace(formatMoneyRegex, ',');
};

export const fromMoneyStringWithCommaToFloat = (currPrice, prefix = window.CONFIG.DEFAULT_VALUES.CURRENCY_SYMBOL) => {
  const price = currPrice.replace(prefix, '');
  return parseFloat(price.replace(/,/g, ''));
};
