import React from 'react';
import { compose, withHandlers } from 'recompose';
import classnames from 'classnames';
import styles from '../../../styles/components/inputs/radio-group.module.scss';
import { Textarea } from '../textarea';
import { InputGroup } from '../input-group';
import { withContainer } from '../input-field';
import { InputRadio } from './radio';

export const RadioGroup = React.memo(
  ({ items = [], plainValue, userAttributeField, onChange, onTextChange, className, value }) => {
    return (
      <InputGroup heir className={classnames(styles.wrapper, className)}>
        {items.map(item => (
          <Radio
            key={item.name}
            value={value}
            data={item}
            plainValue={plainValue}
            userAttributeField={userAttributeField}
            onChange={onChange}
            onTextChange={onTextChange}
          />
        ))}
      </InputGroup>
    );
  }
);

const RadioComponent = React.memo(
  ({ data, plainValue: usePlainValue, onChange, onTextChange, getValueByName, getPlainValue }) => {
    const { name, label, explainer, value: boundValue, type, maxLength, placeholder, helpModal } = data;

    const { checked, text } = usePlainValue ? getPlainValue(boundValue) : getValueByName(name);

    return (
      <>
        <InputRadio
          label={label}
          informer={helpModal}
          explainer={explainer}
          checked={checked}
          onChange={onChange({ name, boundValue, usePlainValue })}
          className={classnames(styles.unchecked, { [styles.checked]: checked })}
        />
        {checked && type && (
          <Textarea placeholder={placeholder} maxLength={maxLength} value={text} onChange={onTextChange(name)} />
        )}
      </>
    );
  }
);

const Radio = compose(
  withHandlers({
    getValueByName:
      ({ value = {} }) =>
      name =>
        value[name] ?? {
          text: '',
          checked: false
        },
    getPlainValue:
      ({ value = {} }) =>
      boundValue => ({ checked: value === boundValue, text: boundValue })
  })
)(RadioComponent);

export const InputRadioGroup = withContainer(RadioGroup);
