import { deleteCookie, readCookie } from '../helpers/cookie';
import { users } from '../api/users';
import { deviceDetection } from '../helpers/device';
import { ShadowUserService } from './shadow-user-service';

export class UserService {
  static login = async data => {
    localStorage.removeItem('shadowId');
    localStorage.removeItem('email');

    localStorage.setItem('token', data.token);
    localStorage.setItem('accessToken', data.accessToken);
    localStorage.setItem('refreshToken', data.refreshToken);
    // lif-2311
    // const firebaseToken = localStorage.getItem('firebaseToken');
    // if (firebaseToken) {
    //   await users.updateUser({ firebaseToken });
    // }
  };

  static postLogin = () => {
    localStorage.removeItem('hasAccount');
  };

  static logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.setItem('hasAccount', 'true');
  };

  static delete = async () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('hasAccount');

    return await ShadowUserService.delete();
  };

  static hasUser = () => {
    return Boolean(localStorage.getItem('token'));
  };

  static hasAccount = () => {
    const hasAccountCookie = readCookie('hasAccount');

    if (hasAccountCookie) {
      localStorage.setItem('hasAccount', hasAccountCookie);
      deleteCookie('hasAccount');
    }

    return Boolean(localStorage.getItem('hasAccount'));
  };

  static track = async () => {
    await users.trackSession({
      device: deviceDetection()
    });
  };
  // lif-2311
  // static updateLocalFirebaseToken = user => {
  //   if (!localStorage.getItem('firebaseToken') && user.firebaseToken) {
  //     localStorage.setItem('firebaseToken', user.firebaseToken);
  //   }
  // };
}
