import { sortByWeight } from '@apps/firsthomecoach/src/helpers/array';
import { CARD_LOCATION_PAGE_TYPES } from './constants';

/**
 * Response Content Item type.
 * @typedef {object} ResponseContentItem
 * @property {string | undefined} key - Key
 * @property {string | null} slug - Slug
 * @property {number} weight - Weight
 */

/**
 * Mapped Content Item type.
 * @typedef {object} MappedContentItem
 * @property {string} ownKey - Own Key
 */

/**
 * @param {Array<ResponseContentItem>} items
 * @return {Array<MappedContentItem>} mappedItems
 */
export const mapContentItems = items =>
  items.map(({ key, slug, ...rest }, i) => {
    return { ...rest, key, slug, ownKey: key || slug || String(i) };
  });

/**
 * @param {Array<MappedContentItem>} targetItems
 * @param {string} filterKey
 * @return {Array<ResponseContentItem>}
 */
export const filterItemsByKey = ({ targetItems = [], filterKey }) => {
  return targetItems.filter(({ ownKey }) => ownKey !== filterKey);
};

/**
 * @param {Array<MappedContentItem>} sourceItems
 * @param {Array<MappedContentItem>} targetItems
 * @param {string} filterKey
 */
export const addUniqueItemByKey = ({ sourceItems = [], targetItems = [], filterKey }) => {
  const newItem = sourceItems.find(({ ownKey }) => ownKey === filterKey);

  const existedItem = targetItems.find(({ ownKey }) => ownKey === filterKey);

  if (newItem && !existedItem) {
    return [...targetItems, newItem];
  }

  return [...targetItems];
};

/**
 * Building query param from the displayed repeaters.
 * @param { Array<{ key: string; widget: object }> } widgetsToShow
 * @returns { string } params
 */
export const mapWidgetsToShowIntoParams = widgetsToShow => {
  const arrayOfRepeatersKeys = new Set(
    widgetsToShow.filter(([_, w]) => Boolean(w.repeaterKey)).map(([_, { repeaterKey }]) => repeaterKey)
  );

  return [...arrayOfRepeatersKeys].join(',');
};

/**
 * Mapping Repeater's API response, sorting items by weight.
 * @param { Array<{ key: string; items: Array<{ weight: number }> }> } arrayOfRepeaters
 * @returns {{ [key: string]: object }}
 */
export const mapRepeatersData = arrayOfRepeaters =>
  arrayOfRepeaters.reduce((list, repeater) => {
    const formattedContentItems = sortByWeight(mapContentItems(repeater.items || []));
    list[repeater.key] = { ...repeater, items: formattedContentItems };
    return list;
  }, {});

/**
 * @typedef {'dashboard' | 'ContentFeed' | 'Article' | 'StartPage' | 'ResultPage' | 'coins'} PageType
 *
 * Get mapped data for status update depending on page type
 * @param { object } options
 * @param { string<PageType> } options.pageType
 * @param { string } options.repeaterBlockName
 * @param { string } options.articleSlug
 * @param { string } options.pageActivityName
 * @returns { object }
 */
export const getAdditionalDataByPageType = ({ pageType, repeaterBlockName, articleSlug, pageActivityName }) => {
  switch (pageType) {
    case CARD_LOCATION_PAGE_TYPES.dashboard:
    case CARD_LOCATION_PAGE_TYPES.coins:
      return { repeaterBlockName };
    case CARD_LOCATION_PAGE_TYPES.Article:
      return { articleSlug };
    case CARD_LOCATION_PAGE_TYPES.StartPage:
    case CARD_LOCATION_PAGE_TYPES.ResultPage:
      return {
        repeaterBlockName,
        pageActivityName
      };
    default:
      return {};
  }
};
