import React from 'react';
import compose from 'recompose/compose';
import classnames from 'classnames';
import styles from '../../../styles/components/radio.module.scss';
import { AffiliateIcon } from '../../icons/affiliate-icon';
import { withAnchor, withContainer, withExplainer, withLabel, withOptionRowClick } from '../input-field';

const Input = React.memo(({ id, className, inputRef, ...props }) => {
  return (
    <input
      ref={inputRef}
      type='radio'
      id={id}
      className={classnames(styles.input, className, 'thrown-away')}
      {...props}
    />
  );
});

const Component = React.memo(({ id, icon = <AffiliateIcon name='radio-input' />, value = false, pulse, ...props }) => {
  return (
    <div>
      <Input id={id} className={styles.field} value={value} {...props} />
      <div className={classnames(styles.icon, { pulse })}>{icon}</div>
    </div>
  );
});

export const Radio = compose(withAnchor, withLabel)(Component);
export const InputRadio = compose(
  withAnchor,
  withContainer,
  withOptionRowClick,
  withExplainer,
  withLabel({ className: styles.label, layout: styles.container })
)(Component);
