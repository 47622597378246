import React from 'react';
import { compose } from 'recompose';
import { CheckboxGroup } from '../../../components/inputs/checkbox/checkbox-group';
import { withButton, withContainer } from './components/input-field';
import { withPreSubmitButton } from './components/pre-submit-button/with-pre-submit-button';

const Input = compose(withContainer, withButton, withPreSubmitButton)(CheckboxGroup);

export const QuestionCheckboxInput = React.memo(
  ({ value, buttonLabel, items, onChange, onInput, onSubmit, type, disabled, loading, preSubmitButton }) => {
    return (
      <Input
        value={value}
        buttonLabel={buttonLabel}
        items={items}
        disabled={disabled || loading}
        type={type}
        onChange={onChange}
        onTextChange={onInput}
        onSubmit={onSubmit}
        preSubmitButton={preSubmitButton}
      />
    );
  }
);
