import { getImageUrlByName } from '../../layout';
import { attachObserverToElement } from './helpers';

const onIntersect = ({ target }) => {
  const data = target.dataset;

  const urlKey = Boolean(data.srcset) ? 'srcset' : 'src';
  const url = data[urlKey];

  const img = new Image();

  img[urlKey] = data[urlKey];

  const clone = target.cloneNode();

  clone.setAttribute(urlKey, url);
  clone.removeAttribute(`data-${urlKey}`);
  clone.removeAttribute('style');

  img.onload = () => {
    target.parentNode.replaceChild(clone, target);
  };
};

export const changeImages = element => {
  const media = [...element.querySelectorAll('.wp-block-image, .wp-block-media-text__media')];

  media.forEach(entry => {
    const target = entry.querySelector('img');

    const urlKey = Boolean(target.srcset) ? 'srcset' : 'src';
    const url = target[urlKey];

    const fallbackImage = getImageUrlByName('fallback-image', 'png');

    const clone = target.cloneNode();

    clone.setAttribute(urlKey, fallbackImage);
    clone.setAttribute(`data-${urlKey}`, url);

    // Template image should get awailable width but the height
    // should be 'auto'. That is guarantee that image would be contain
    // inside a template grid.

    // When height is 'auto' and real image has less height then a 'fallback'
    // 'fallback' would take more space then it should and push the next content.
    // it would later lead to 'content jump' when real image would appear.

    // We can not predict the exact height of the provided image,
    // but we can set 'max-height' of a 'fallback' to be equal to real image 'height'.

    const height = parseInt(clone.getAttribute('height'));

    if (height) {
      clone.style.maxHeight = height + 'px';
    }

    const observer = attachObserverToElement(onIntersect, {
      once: true,
      threshold: 0.1
    });

    observer.observe(clone);

    target.parentNode.replaceChild(clone, target);
  });

  return element;
};
