import React from 'react';
import { InputWithButton as Input } from './components/input-with-button';

export const QuestionPercentInput = React.memo(
  ({
    label,
    format,
    value,
    max,
    min,
    errorMessages,
    onChange,
    onError,
    onSubmit,
    loading,
    disabled,
    buttonLabel,
    placeholder,
    preSubmitButton,
    explainer,
    isRequired
  }) => (
    <Input
      type='tel'
      placeholder={placeholder}
      label={label}
      format={format}
      value={value}
      maxLength={3}
      max={max}
      min={min}
      errorMessages={errorMessages}
      mask='9.99'
      maskChar='0'
      onError={onError}
      onChange={onChange}
      onSubmit={onSubmit}
      loading={loading}
      disabled={disabled}
      buttonLabel={buttonLabel}
      preSubmitButton={preSubmitButton}
      explainer={explainer}
      isRequired={isRequired}
    />
  )
);
