import { lifecycle } from 'recompose';
import { accountChunks, pointsChunks } from '../../../helpers/import-modules';
import { UserService } from '../../../services/user-servcie';

export const withDefaultContent = lifecycle({
  async componentDidMount() {
    const { getUserMarketingPreferences, getUserAttributes, getValidations, getPoints, getRewardCards } = this.props;
    const {
      DISPLAY_MODULES: { ACCOUNT, POINTS, REWARDS }
    } = window.CONFIG;

    ACCOUNT && accountChunks();
    POINTS && pointsChunks();

    if (!UserService.hasAccount()) {
      await getUserMarketingPreferences();

      if (POINTS) {
        await getPoints();

        if (REWARDS) {
          await getRewardCards();
        }
      }
      await getValidations();
      await getUserAttributes();

      // eslint-disable-next-line no-console
      console.log('[HBC] All required data is loaded', new Date());
    }
  }
});
