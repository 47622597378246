import React from 'react';
import { affiliate } from '../../../../helpers/main';
import { isVisible } from '../../with-layout';
import { Link } from '../../../link';
import { Image } from '../../../images/image';
import { ConfigService } from '../../../../services/config-service';
import styles from '../../../../styles/components/application-header.module.scss';

const Component = React.memo(() => {
  const { logoAlt = `${affiliate} site logotype`, logoUrl, logoLink } = ConfigService.get('NAVIGATION_BAR', {});

  return (
    <div className={styles.logo}>
      <Link className={styles.link} to={logoLink}>
        <Image src={logoUrl} title={logoAlt} fluid />
      </Link>
    </div>
  );
});

export const AppLogo = isVisible({
  name: 'appLogo'
})(Component);
