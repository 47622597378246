import React from 'react';
import { compose, withHandlers } from 'recompose';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { RadioGroup } from '../../../components/inputs/radio/radio-group';
import { withButton, withContainer } from './components/input-field';
import { withPreSubmitButton } from './components/pre-submit-button/with-pre-submit-button';

// Expanding, onSubmit logic with ability to send specific statusData {activityKey, activityStatusKey} on selected radio item, if set.
// Used for buying vouchers
// works only as an alone input (not in GroupInput)
const withItemStatusData = withHandlers({
  onSubmit:
    ({ onInputSubmit, sendStatus, value, items }) =>
    () => {
      // getting the selected item status data
      const option = Object.keys(value)[0];
      const selectedItem = items.find(({ name }) => name === option);
      if (ObjectShim.isObject(selectedItem?.statusData)) {
        sendStatus(selectedItem.statusData);
      }
      //call default onSubmit
      onInputSubmit();
    }
});

const Input = compose(withContainer, withItemStatusData, withButton, withPreSubmitButton)(RadioGroup);

export const QuestionRadioInput = React.memo(
  ({
    value,
    buttonLabel,
    items,
    plainValue,
    userAttributeField,
    onChange,
    onInput,
    onSubmit,
    loading,
    disabled,
    preSubmitButton,
    sendStatus
  }) => {
    return (
      <Input
        value={value}
        buttonLabel={buttonLabel}
        items={items}
        plainValue={plainValue}
        userAttributeField={userAttributeField}
        disabled={disabled}
        onChange={onChange}
        onTextChange={onInput}
        onInputSubmit={onSubmit}
        loading={loading}
        preSubmitButton={preSubmitButton}
        sendStatus={sendStatus}
      />
    );
  }
);
