import React from 'react';
import { componentFromProp, compose, mapProps, renderNothing } from 'recompose';
import { withInputHandlers } from '../hocs/with-handlers';
import { QuestionTextInput } from './text';
import { QuestionButtonInput } from './button';
import { QuestionNumberInput } from './number';
import { QuestionPercentInput } from './percent';
import { QuestionAddressInput } from './address';
import { QuestionDateInput } from './date';
import { QuestionWheelInput } from './wheel';
import { QuestionTextareaInput } from './textarea';
import { QuestionCheckboxInput } from './checkbox';
import { QuestionRadioInput } from './radio';
import { QuestionPensionProviderInput } from './pension-provider';
import { QuestionEmailInput } from './email';
import { QuestionPhoneNumberInput } from './phone-number';
import { QuestionNiNInput } from './nin';
import { QuestionSelectInput } from './select';
import { QuestionPropertyAddressInput } from './property-address';
import { QuestionInfoButton } from './button-info';
import { QuestionGroupInput } from './group';
import { QuestionDatasetInput } from './dataset';
import { QuestionIntroducerAutoCompleteInput } from './dataset-with-introducers';
import { QuestionIntroducerInput } from './introducer';
import { QuestionSegmentedButton } from './segmented-button';
import { withContainer } from './components/input-field';

export const Input = compose(
  mapProps(({ type, data, ...props }) => ({
    ...props,
    ...data,
    type,
    component: components[type] ?? renderNothing
  })),
  withInputHandlers
)(componentFromProp('component'));

export const PureGroupInput = React.memo(({ onSubmit, buttonLabel, preSubmitButton, ...props }) => {
  return (
    <QuestionGroupInput
      onSubmit={onSubmit}
      {...props}
      buttonLabel={buttonLabel}
      preSubmitButton={preSubmitButton}
      value={null}
    >
      {({ key, ...props }) => {
        return <Input key={key} {...props} />;
      }}
    </QuestionGroupInput>
  );
});

const GroupInput = compose(withContainer)(PureGroupInput);

const components = {
  button: QuestionButtonInput,
  text: QuestionTextInput,
  number: QuestionNumberInput,
  percent: QuestionPercentInput,
  address: QuestionAddressInput,
  propertyAddress: QuestionPropertyAddressInput,
  date: QuestionDateInput,
  wheel: QuestionWheelInput,
  textarea: QuestionTextareaInput,
  checkbox: QuestionCheckboxInput,
  radio: QuestionRadioInput,
  pensionProvider: QuestionPensionProviderInput,
  email: QuestionEmailInput,
  phoneNumber: QuestionPhoneNumberInput,
  nin: QuestionNiNInput,
  select: QuestionSelectInput,
  infoButton: QuestionInfoButton,
  groupParams: GroupInput,
  groupParamsMarketingList: GroupInput,
  dataset: QuestionDatasetInput,
  segmentedButton: QuestionSegmentedButton,
  introducer: QuestionIntroducerInput,
  introducerAutocomplete: QuestionIntroducerAutoCompleteInput
};
