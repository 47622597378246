import { helpers } from './helpers';
import { userAttributes } from './user-attributes';
import { users } from './users';
import { depositBuilder } from './deposit-builder';
import { content } from './content';
import { statusData } from './status-data';
import { marketingInformation } from './marketing-info';
import { points } from './points-and-awards';
import { configuration } from './configuration';
import { eligibility } from './eligibility';
import { propertyPortfolio } from './property-portfolio';
import { affordability } from './affordability';
import { dataset } from './dataset';
import { marketingList } from './custom-action';
import { propertyAddress } from './property-address';
import { statusDataContent } from './status-data-content';
import { repeaters } from './repeater';
import { stories } from './stories';
import { guidance } from './guidance';
import { introducer } from './introducer';
import { footprintCalculator } from './footprint-calculator';

export const api = {
  geocode: helpers,
  userAttributes,
  users,
  depositBuilder,
  content,
  repeaters,
  stories,
  statusData,
  marketingInformation,
  points,
  configuration,
  eligibility,
  propertyPortfolio,
  affordability,
  dataset,
  marketingList,
  propertyAddress,
  statusDataContent,
  guidance,
  introducer,
  footprintCalculator
};
