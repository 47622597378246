import React from 'react';
import compose from 'recompose/compose';
import styles from '../../styles/components/tick.module.scss';
import { AffiliateIcon } from '../icons/affiliate-icon';
import { Input } from './checkbox/checkbox';
import { withAnchor, withContainer, withLabel } from './input-field';

const Component = React.memo(
  ({ id, name, icon = <AffiliateIcon name='checkbox-done' />, ticked = false, ...props }) => (
    <div>
      <Input id={id} className={styles.field} checked={ticked} disabled {...props} />
      <label htmlFor={id} className={styles.icon}>
        {icon}
      </label>
    </div>
  )
);

export const Tick = withAnchor(Component);
export const InputTick = compose(
  withAnchor,
  withContainer,
  withLabel({ className: styles.label, layout: styles.container })
)(Component);
