import React, { useEffect } from 'react';
import classnames from 'classnames';
import { compose, defaultProps, withPropsOnChange } from 'recompose';
import { NumberShim } from '@packages/helpers/core/shims/number-shim';
import styles from '../../styles/components/pie-chart.module.scss';
import { withMemoAndRef } from '../../helpers/hocs/with-memo-and-ref';

const SIZE = 100;
const RADIUS = SIZE / 2;
const CENTER = SIZE / 2;

export const PieChartContainer = withMemoAndRef(({ children, className, style }, ref) => (
  <div ref={ref} className={classnames(styles.container, className)} style={style}>
    {children}
  </div>
));

const RingComponent = withMemoAndRef(
  (
    {
      value,
      lowerValue,
      strokeWidth,
      radius,
      lowerRadius,
      lowerCircumference,
      circumference,
      completed,
      className,
      style,
      onChange,
      progressOver100,
      lowerProgressOver100,
      ariaLabel
    },
    ref
  ) => {
    useEffect(() => {
      onChange && onChange(completed);
    }, [completed, onChange]);
    //TODO: Move ariaLabel to config?
    return (
      <svg
        ref={ref}
        role='img'
        className={classnames(styles.ring, className)}
        style={style}
        viewBox={`0 0 ${SIZE} ${SIZE}`}
        aria-label={`${ariaLabel} doughnut`}
      >
        <g>
          <circle
            className={styles.circle}
            r={radius}
            cx={CENTER}
            cy={CENTER}
            style={{
              strokeWidth,
              strokeDasharray: `${circumference} ${circumference}`
            }}
          />
          <circle
            className={classnames(styles.circle, styles.progress)}
            r={radius}
            cx={CENTER}
            cy={CENTER}
            style={{
              strokeWidth,
              strokeDasharray: `${value} ${circumference}`,
              strokeOpacity: Number(!!value)
            }}
          />
          <circle
            className={classnames(styles.circle, styles['lower-progress'])}
            r={lowerRadius}
            cx={CENTER}
            cy={CENTER}
            style={{
              strokeWidth,
              strokeDasharray: `${lowerValue} ${lowerCircumference}`,
              strokeOpacity: Number(!!lowerValue)
            }}
          />
          {progressOver100 && (
            <circle
              className={styles.marker}
              r={strokeWidth / 2}
              cx='50'
              cy={strokeWidth / 2}
              style={{
                transform: `rotate(${90 + progressOver100}deg)`,
                strokeDasharray: `${progressOver100} ${circumference}`
              }}
            />
          )}
          {lowerProgressOver100 && (
            <circle
              className={styles['lower-marker']}
              r={strokeWidth / 2}
              cx='50'
              cy={strokeWidth / 2}
              style={{
                transform: `rotate(${90 + lowerProgressOver100}deg)`,
                strokeDasharray: `${lowerProgressOver100} ${lowerCircumference}`
              }}
            />
          )}
        </g>
      </svg>
    );
  }
);

RingComponent.displayName = 'RingComponent';

export const PieChartRing = compose(
  withPropsOnChange(['strokeWidth'], ({ strokeWidth }) => {
    const radius = RADIUS - NumberShim.inRange(strokeWidth, { min: 1, max: RADIUS }) / 2;
    const circumference = 2 * Math.PI * radius;
    const lowerRadius = RADIUS - NumberShim.inRange(strokeWidth, { min: 1, max: RADIUS }) / 2 - strokeWidth;
    const lowerCircumference = 2 * Math.PI * radius - strokeWidth;

    return {
      radius,
      circumference,
      lowerRadius,
      lowerCircumference
    };
  }),
  withPropsOnChange(
    ['value', 'circumference', 'lowerValue', 'lowerCircumference', 'target'],
    ({ value, circumference, lowerValue, lowerCircumference, target, isLoop }) => {
      let progress = circumference;
      let progressOver100 = 0;
      let lowerProgress = lowerCircumference;
      let lowerProgressOver100 = 0;

      if (target !== 0) {
        progress = NumberShim.inRange(value, { min: 0, max: 100 });
        progress = NumberShim.percentIs(progress, circumference, false);
        lowerProgress = NumberShim.inRange(lowerValue, { min: 0, max: 100 });
        lowerProgress = NumberShim.percentIs(lowerProgress, lowerCircumference, false);

        if (value > 100 && isLoop) {
          progressOver100 = value % 100;
          progressOver100 = NumberShim.percentIs(progressOver100, 360, false);
        }
        if (lowerValue > 100 && isLoop) {
          lowerProgressOver100 = lowerValue % 100;
          lowerProgressOver100 = NumberShim.percentIs(lowerProgressOver100, 360, false);
        }
      }

      return {
        value: progress,
        lowerValue: lowerProgress,
        completed: progress === circumference,
        progressOver100,
        lowerProgressOver100
      };
    }
  )
)(RingComponent);

PieChartRing.displayName = 'PieChartRing';

const ChildrenComponent = withMemoAndRef(({ className, children, style, padding }, ref) => (
  <div ref={ref} className={classnames(styles.content, className)} style={{ ...style, padding }}>
    <div className={styles.children}>{children}</div>
  </div>
));

export const PieChartChildren = compose(
  withPropsOnChange(['strokeWidth'], ({ strokeWidth }) => {
    const padding = `0 ${strokeWidth}%`;

    return {
      padding: { padding }
    };
  })
)(ChildrenComponent);

const Component = ({ children, className, ...rest }) => (
  <PieChartContainer className={className}>
    <PieChartRing {...rest} />
    <PieChartChildren {...rest}>{children}</PieChartChildren>
  </PieChartContainer>
);

export const PieChart = defaultProps({
  strokeWidth: 6,
  value: 0
})(Component);

PieChart.displayName = 'PieChart';
