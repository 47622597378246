const getDefaultHeaders = () => {
  return new Headers({ 'Content-Type': 'application/json' });
};

export const createHeaders = type => {
  const headers = getDefaultHeaders();
  const token = localStorage.getItem('token');
  const shadowId = localStorage.getItem('shadowId');

  switch (type) {
    case 'content':
    case 'static':
      break;
    case 'main':
    default: {
      if (token) {
        headers.set('authorization', token);
        headers.set('X-Shadow-User', '');
      }

      if (shadowId && !token) {
        headers.set('X-Shadow-User', shadowId);
        headers.set('authorization', '');
      }
      break;
    }
  }

  return headers;
};
