import React from 'react';
import { AutocompleteInput } from './components/autocomplete';
import { withDatasetAutocomplete } from './components/dataset/handlers';

const Input = withDatasetAutocomplete(AutocompleteInput);

export const QuestionDatasetInput = React.memo(
  ({
    label,
    format,
    value,
    max,
    min,
    errorMessages,
    onChange,
    onError,
    onSubmit,
    userAttributeType,
    userAttributeField,
    datasetKey,
    loading,
    disabled,
    buttonLabel,
    limit,
    type,
    placeholder,
    preSubmitButton,
    explainer,
    isRequired
  }) => {
    const userAttributeName = `${userAttributeType}.${userAttributeField}`;

    return (
      <Input
        type={type}
        placeholder={placeholder}
        label={label}
        format={format}
        value={value}
        max={max}
        min={min}
        errorMessages={errorMessages}
        onError={onError}
        onChange={onChange}
        onSubmit={onSubmit}
        userAttributeName={userAttributeName}
        datasetKey={datasetKey}
        loading={loading}
        disabled={disabled}
        buttonLabel={buttonLabel}
        limit={limit}
        preSubmitButton={preSubmitButton}
        explainer={explainer}
        isRequired={isRequired}
      />
    );
  }
);
