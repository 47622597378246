import React from 'react';
import { InputWithButton as Input } from './components/input-with-button';

export const QuestionNumberInput = React.memo(
  ({
    label,
    format,
    value,
    max,
    min,
    errorMessages,
    explainer,
    mask,
    maskChar,
    onChange,
    onError,
    onSubmit,
    disabled,
    loading,
    buttonLabel,
    placeholder,
    preSubmitButton,
    isRequired
  }) => {
    const defaultFormat = !mask && !format ? 'number' : format;

    return (
      <Input
        type='tel'
        placeholder={placeholder}
        label={label}
        format={defaultFormat}
        value={value}
        maxLength={15}
        max={max}
        min={min}
        errorMessages={errorMessages}
        mask={mask}
        maskChar={maskChar}
        onChange={onChange}
        onError={onError}
        onSubmit={onSubmit}
        loading={loading}
        disabled={disabled}
        buttonLabel={buttonLabel}
        preSubmitButton={preSubmitButton}
        explainer={explainer}
        isRequired={isRequired}
      />
    );
  }
);
