import React from 'react';
import uuid from 'uuid';
import { connect } from 'react-redux';
import { branch, compose, renderComponent, withProps, withHandlers } from 'recompose';
import { Redirect } from 'react-router-dom';
import { getMarketingListsByAccountType } from '../../../helpers/account';
import { UserService } from '../../../services/user-servcie';
import { sendConsents } from '../../../store/reducers/user-attributes/actions';
import { InputGroup } from '../../../components/inputs/input-group';
import { InputToggleSwitch as ToggleSwitch } from '../../../components/inputs/toggle-switch';
import { InputCheckbox as Checkbox } from '../../../components/inputs/checkbox/checkbox';
import { InputTick as Tick } from '../../../components/inputs/tick';
import { withContainer } from '../../../components/inputs/input-field';
import { withLoadingHandlers } from '../../../components/with-loader';
import { PurifiedHtmlContainer } from '../../../components/layout/purified-html-container';
import { withBarrier } from '../../barrier';
import { ConfigService } from '../../../services/config-service';
import styles from '../../../styles/components/account-register-password.module.scss';

const Disclaimer = React.memo(() => {
  const { label } = ConfigService.get('ACCOUNT.TERMS_AND_PRIVACY', {});

  return <PurifiedHtmlContainer renderAs='span' className='typography' html={label} />;
});

export const TermsAndPrivacy = React.memo(({ agree, pulse, onChange, ...rest }) => {
  return <Checkbox label={<Disclaimer />} checked={agree} pulse={pulse} onChange={onChange} {...rest} />;
});

const ValidatorsComponent = React.memo(({ items }) => {
  const { label } = ConfigService.get('ACCOUNT.VALIDATIONS', {});

  return (
    <div className={styles.validators}>
      <p className='label-s content strong'>{label}</p>
      <InputGroup type='stack'>
        {items.map(({ value, text }) => (
          <Tick key={uuid('account-validator-tick')} label={text} ticked={value} />
        ))}
      </InputGroup>
    </div>
  );
});

export const Validators = withContainer(ValidatorsComponent);

const MarketingListsComponent = React.memo(({ label, lists, disabled, values, handleChange }) => {
  return (
    <div>
      {label && <p className='label-s content strong'>{label}</p>}
      <InputGroup>
        {lists.map(({ id, name, description }) => (
          <ToggleSwitch
            key={id}
            name={name}
            label={<PurifiedHtmlContainer renderAs='span' html={name} className='typography' />}
            explainer={<PurifiedHtmlContainer renderAs='span' html={description} className='typography' />}
            value={values[id]}
            onChange={handleChange(id)}
            disabled={disabled}
          />
        ))}
      </InputGroup>
    </div>
  );
});

export const MarketingLists = compose(
  connect(state => ({
    lists: getMarketingListsByAccountType(state.marketingLists.lists)
  })),
  withHandlers({
    handleChange:
      ({ onChange }) =>
      id =>
      ({ target: { checked } }) => {
        onChange && onChange(id, checked);
      }
  }),
  withContainer
)(MarketingListsComponent);

const ConsentListsComponent = React.memo(
  ({ value, label, consents, handleChange, loading, onChange, attributesConsent }) => {
    return (
      <div>
        {label && <p className='label-s content strong'>{label}</p>}
        <InputGroup>
          {consents
            .sort((a, b) => a.weight - b.weight)
            .map(({ id, title, body, type, locked }) => (
              <ToggleSwitch
                key={id}
                name={id}
                label={title}
                explainer={body}
                value={onChange ? value[type] : attributesConsent[type]}
                onChange={handleChange(type)}
                disabled={locked || loading}
              />
            ))}
        </InputGroup>
      </div>
    );
  }
);

export const ConsentLists = compose(
  connect(
    state => ({
      consents: state.consentList.list,
      attributesConsent: state.userAttributes.consents
    }),
    { sendConsents }
  ),
  withLoadingHandlers(),
  withHandlers({
    handleChange:
      ({ sendConsents, turnOnLoading, turnOffLoading, onChange, value }) =>
      type =>
      async ({ target: { checked } }) => {
        if (!onChange) {
          turnOnLoading();
          await sendConsents({ [type]: checked });
          turnOffLoading();
          return;
        }

        onChange({ ...value, [type]: checked });
      }
  }),
  withContainer
)(ConsentListsComponent);

const Account = () => <Redirect to='/account' />;
const Login = () => <Redirect to='/account/login' />;
const FirstStep = React.memo(({ firstStemp }) => <Redirect to={firstStemp} />);

export const withUser =
  (RedirectTo = Account) =>
  Component => {
    return compose(branch(() => UserService.hasUser(), renderComponent(RedirectTo)))(Component);
  };

export const withoutUser =
  (RedirectTo = Login) =>
  Component => {
    return compose(
      withBarrier,
      branch(() => !UserService.hasUser(), renderComponent(RedirectTo))
    )(Component);
  };

export const withCompletedPrevStep = firstStemp => Component => {
  const PropsMapper = withProps(() => ({ firstStemp }));

  return branch(
    ({ location: { state } }) => state && state.completed,
    renderComponent(Component),
    renderComponent(compose(PropsMapper)(FirstStep))
  )();
};

export const withoutMarketingLists = firstStemp => Component => {
  const PropsMapper = withProps(() => ({ firstStemp }));

  return branch(
    ({ lists }) => lists && lists.length,
    renderComponent(Component),
    renderComponent(compose(PropsMapper)(FirstStep))
  )();
};
