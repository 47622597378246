import dayjs from 'dayjs';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import {
  mabUserNameApostrophesRegex,
  mabUserNameExcludedRegex,
  mabUserNameRegex,
  mabUserNameRepeatedRegex,
  mabUserPhoneNumberRegex
} from '../../../helpers/regex';

export const applyAdditionalRules = (type, currentValidation, rulesToApply) => {
  if (!additionalRules[type] || !ObjectShim.isObject(rulesToApply)) {
    return;
  }
  Object.keys(rulesToApply).forEach(key => {
    if (additionalRules[type][key]) {
      currentValidation.rule(value => {
        try {
          const data = rulesToApply[key];

          return additionalRules[type][key](value, data);
        } catch {
          return true;
        }
      }, rulesToApply[key].errorMessage);
    }
  });
};

const additionalRules = {
  date: {
    'date-of-birth': (value, { limit = 16 }) => {
      const date = dayjs(value, 'DD/MM/YYYY');
      const today = dayjs();

      return today.diff(date, 'year') >= limit;
    }
  },
  phoneNumber: {
    'mab-phone-number': value => mabUserPhoneNumberRegex.test(value)
  },
  text: {
    'mab-user-name': value => {
      const userNameMatch = mabUserNameRegex.test(value);
      const userNameApostrophesMatch = mabUserNameApostrophesRegex.test(value);
      const userNameExcludedMatch = mabUserNameExcludedRegex.test(value);
      const userNameRepeatedMatch = mabUserNameRepeatedRegex.test(value);

      return userNameMatch && !userNameApostrophesMatch && !userNameExcludedMatch && !userNameRepeatedMatch;
    }
  }
};
