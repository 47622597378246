import { connect } from 'react-redux';
import { branch, compose, lifecycle, renderNothing, withState } from 'recompose';
import { getKnowledgeBase } from '../../store/reducers/content';
import { resetNavKnowledgeLink } from '../../store/reducers/navigation';
import { withLoader, withLoadingHandlers } from '../../components/with-loader';
import { CONTENT_HOME_PAGE_TYPE } from '../../helpers/knowledgebase/category-filter';
import { withKnowledgeBaseDisplayCondition } from './with-display-condition';
import { withPostsCategoryFilter } from './with-posts-category-filter';

export const withKnowledgeBase = compose(
  connect(null, { getKnowledgeBase, resetNavKnowledgeLink }),
  withLoadingHandlers(true),
  withState('knowledgeBase', 'setKnowledgeBase', []),
  withPostsCategoryFilter(CONTENT_HOME_PAGE_TYPE.ARTICLES),
  withKnowledgeBaseDisplayCondition,
  lifecycle({
    async componentDidMount() {
      const { getKnowledgeBase, resetNavKnowledgeLink, filterTopics, setKnowledgeBase, turnOffLoading } = this.props;

      const knowledgeBase = await getKnowledgeBase();
      const filteredTopics = await filterTopics(knowledgeBase);

      setKnowledgeBase(filteredTopics);
      resetNavKnowledgeLink(filteredTopics);

      turnOffLoading();
    }
  }),
  withLoader,
  branch(({ knowledgeBase }) => !knowledgeBase.length, renderNothing)
);
