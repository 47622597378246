import React from 'react';
import { branch } from 'recompose';
import { isNil } from '@packages/helpers/core/common';
import { ariaDisabledInterceptor } from '../../helpers/aria-disabled-interceptor';

const renderAsButton =
  Component =>
  ({ type, label, onClick, className, ariaLabel, ariaDisabled, ...props }) => {
    const { name, inline } = props;

    return (
      <button
        style={{ display: inline ? 'inline-block' : 'block' }}
        type={type}
        className={className}
        aria-label={ariaLabel ?? label ?? name}
        aria-disabled={ariaDisabled}
        onClick={ariaDisabledInterceptor(onClick)}
      >
        <Component {...props} />
      </button>
    );
  };

export const withButton = branch(({ onClick }) => !isNil(onClick), renderAsButton);
