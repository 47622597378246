import React, { useState } from 'react';
import classnames from 'classnames';
import { affiliate } from '../../../../../helpers/main';
import styles from '../../../../../styles/components/desktop-application-header.module.scss';
import { ConfigService } from '../../../../../services/config-service';
import { Link } from '../../../../link';
import { Image } from '../../../../images/image';
import { Grid, Column } from '../../../grid';
import { SkipLink } from '../../../skip-link';
import { withApplicationHeader } from '../../component';
import { HeaderIcon } from '../../default';
import { CollapsedNav } from './collapsed-nav';
import { DefaultNav } from './default-nav';

export const ApplicationHeader = React.memo(({ className }) => {
  const {
    logoUrl,
    logoAlt = `${affiliate} site logotype`,
    logoLink,
    linkAriaLabel,
    buttons
  } = ConfigService.get('NAVIGATION_BAR', {});

  const [expanded, setExpanded] = useState(false);
  const toggle = () => setExpanded(!expanded);

  return (
    <>
      <header className={classnames(styles.header, className)}>
        <SkipLink />
        <Grid>
          <Column>
            <div className={styles.container}>
              <Link ariaLabel={linkAriaLabel} className={styles.link} to={logoLink}>
                <Image src={logoUrl} title={logoAlt} className={styles.logo} />
              </Link>
              <nav>
                <DefaultNav items={buttons} />
                <div className={styles.icons}>
                  <HeaderIcon
                    className={classnames(styles.toggle, { [styles.expanded]: expanded })}
                    onClick={toggle}
                    name={expanded ? 'nav-exit' : 'nav-menu'}
                  />
                </div>
              </nav>
            </div>
          </Column>
        </Grid>
      </header>
      {expanded ? <CollapsedNav expanded={expanded} items={buttons} toggle={setExpanded} /> : null}
    </>
  );
});

export const DesktopHeader = withApplicationHeader(ApplicationHeader);
