import Bugsnag from '@bugsnag/js';

export class BugsnagService {
  static isInitialized = false;

  static initialize() {
    try {
      if (process.env.REACT_APP_BUGSNAG_API_KEY !== 'false') {
        Bugsnag.start({
          apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
          onError: event => {
            event.setUser(localStorage.getItem('token') ? 'Registered User' : 'Shadow User');
          }
        });

        BugsnagService.isInitialized = true;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('[Bugsnag] Failed to initialize', err);
    }
  }

  static notify(error) {
    if (BugsnagService.isInitialized) {
      try {
        Bugsnag.notify(new Error(error));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('[Bugsnag] Unable to notify', err);
      }
    }
  }
}
