import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import {
  sendUserAttribute,
  sendAdditionalUserAttributes,
  sendUserGroupAttributes
} from '../../store/reducers/user-attributes/actions';
import { sendStatus } from '../../store/reducers/status-data';
import { subscribeUnsubscribeMarketingList } from '../../store/reducers/custom-action';
import { withNextPage } from './hocs/with-next-page';
import { Input as Component } from './inputs';
import { withForm } from './hocs/with-form';

const mapStateToProps = (state, ownProps) => {
  const { data, identifier } = ownProps;

  const attributeType = data.userAttributeType;

  const reusableType = attributeType?.split('.')[0];
  const name = attributeType?.split('.')[1];

  //this part is used for question with dynamic names e.g. pension-finder
  //type consists of two parts, 1-user attribute type (e.g. pension, goal), 2 - items (object)
  if (name && identifier) {
    return {
      previousState: state.userAttributes?.[reusableType]?.[name] ?? {},
      name,
      attributeType: reusableType
    };
  }

  return {
    attributeType
  };
};

const mapDispatchToProps = dispatch => ({
  sendUserAttribute: (type, params, cb) => dispatch(sendUserAttribute(type)(params, cb)),
  sendAdditionalUserAttributes: (params, mergeObjects) => dispatch(sendAdditionalUserAttributes(params, mergeObjects)),
  sendUserGroupAttributes: (params, mergeObjects) => dispatch(sendUserGroupAttributes(params, mergeObjects)),
  subscribeUnsubscribeMarketingList: params => dispatch(subscribeUnsubscribeMarketingList(params)),
  sendStatus: ({ activityKey, activityStatusKey }) => dispatch(sendStatus(activityKey, activityStatusKey))
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { name, previousState, attributeType } = stateProps;
  const { identifier, data } = ownProps;

  const sendUserAttribute = type => async (params, cb) => {
    if (name && identifier) {
      return await dispatchProps.sendUserAttribute(
        type,
        {
          [name]: { ...previousState, [identifier]: { ...previousState[identifier], ...params } }
        },
        cb
      );
    }

    return await dispatchProps.sendUserAttribute(type, params, cb);
  };

  const sendStatusUpdate = () => {
    const { statusData } = data;

    if (statusData) {
      return dispatchProps.sendStatus(statusData);
    }
  };

  return { ...dispatchProps, ...ownProps, attributeType, sendUserAttribute, sendStatusUpdate };
};

// TODO: Probably will be a good idea to move button here ?
export const Input = compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withRouter,
  withNextPage,
  withForm
)(Component);
