import { lifecycle } from 'recompose';

export const withEmbeddedContent = lifecycle({
  async componentDidMount() {
    const { getUserAttributes, getValidations, sendApplicationData } = this.props;

    await sendApplicationData({ isEmbedded: true });
    await getValidations();
    await getUserAttributes();

    // eslint-disable-next-line no-console
    console.log('[HBC] All required data is loaded', new Date());
  }
});
