import React from 'react';
import classnames from 'classnames';
import styles from '../../styles/components/navigation-bar.module.scss';
import { getConfig } from '../../api/config';
import { NativeAction } from '../../services/native-action';
import { withMemoAndRef } from '../../helpers/hocs/with-memo-and-ref';
import { AffiliateIcon } from '../icons/affiliate-icon';
import { NavLink } from '../link';

export const getNewConfig = nativeTrigger => async e => {
  if (nativeTrigger) {
    e.preventDefault();

    NativeAction.trigger(nativeTrigger);
  } else {
    window.CONFIG = await getConfig();
  }
};

export const NavigationBar = withMemoAndRef(({ items, top, className }, ref) => (
  <ul ref={ref} className={classnames(styles.container, { [styles.top]: top }, className)}>
    {items.map(({ icon, labelText, link, nativeTrigger }) => (
      <li key={labelText} className={styles.item}>
        <NavLink
          to={link}
          className={styles.link}
          activeClassName={styles.active}
          replace={(to, { pathname }) => pathname.includes(to)}
          onClick={getNewConfig(nativeTrigger)}
        >
          <AffiliateIcon name={icon} className={styles.icon} />
          <span className={styles.label}>{labelText}</span>
        </NavLink>
      </li>
    ))}
  </ul>
));
