import React from 'react';
import { connect } from 'react-redux';
import { branch, compose, lifecycle, renderNothing, withHandlers, withPropsOnChange, withState } from 'recompose';
import { Column, Grid } from '@packages/ui-kit/core/grid';
import { FavoriteButton } from '../../components/layout/application-header/components/favorite-button';
import { Footer } from '../../components/layout/page-footer';
import { Slot } from '../../components/slots';
import { TYPE_TO_ACTIVITY_KEY } from '../../helpers/content-interaction/constants';
import { withLoader } from '../../components/with-loader';
import { ProgressIndicator } from '../../components/progress-indicator';
import { sendContentStatus } from '../../store/reducers/content-interaction';
import { getStories } from '../../store/reducers/stories';
import { withMetaTags } from '../../components/meta-tags';
import styles from '../../styles/templates/story-template.module.scss';
import { StorySlider } from './components/story-slider';

const Component = React.memo(props => {
  const { sortedSteps, beforeChange, stepNumber, stepsTotal, storyKey, storySlug, storyType, reportStatus } = props;
  const description = 'Please, use left and right arrows to navigate between the slides!';

  return (
    <>
      <Slot name='top-navigation-right'>
        <FavoriteButton slug={storySlug} ownKey={storyKey} type={storyType} />
      </Slot>
      <ProgressIndicator pageNumber={stepNumber} pagesTotal={stepsTotal} grid />
      <Grid className={styles.body}>
        <Column id='article' tabIndex={0} role='slider' aria-label={description}>
          <StorySlider
            storyKey={storyKey}
            sortedSteps={sortedSteps}
            beforeChange={beforeChange}
            reportStatus={reportStatus}
          />
        </Column>
        <div className={styles['arrows-space']} />
      </Grid>
      <Footer pageType='story' />
    </>
  );
});

Component.displayName = 'StoryTemplateComponent';

const mapStateToProps = ({ stories }, { match }) => {
  const story = stories.list[match.params.slug];

  return {
    story,
    storyKey: story?.key,
    storySlug: story?.slug,
    storyType: TYPE_TO_ACTIVITY_KEY.story,
    storyPages: story?.storyPages ?? [],
    loading: stories.loading
  };
};

const DEFAULT_STEP_NUMBER = 1;
const SEO_PAGE_DATA_NAME = 'stories';

export const StoryTemplate = compose(
  connect(mapStateToProps, { getStories, sendContentStatus }),
  withMetaTags(SEO_PAGE_DATA_NAME),
  withState('stepNumber', 'setStep', DEFAULT_STEP_NUMBER),
  withPropsOnChange(['storyPages'], ({ storyPages }) => {
    const sortedSteps = storyPages.sort((a, b) => a.pageNumber - b.pageNumber);

    return {
      sortedSteps,
      stepsTotal: sortedSteps.length
    };
  }),
  withHandlers({
    reportStatus:
      ({ story: { key }, sendContentStatus }) =>
      status => {
        const options = {
          activityKey: TYPE_TO_ACTIVITY_KEY.story,
          activityStatusKey: status,
          data: { key }
        };
        sendContentStatus(options);
      },
    resetStepNumber:
      ({ setStep }) =>
      () =>
        setStep(DEFAULT_STEP_NUMBER)
  }),
  withHandlers({
    getStoryBySlug:
      ({ reportStatus, getStories, resetStepNumber, match }) =>
      async () => {
        await getStories(match.params.slug);
        reportStatus('seen');
        resetStepNumber();
      }
  }),
  lifecycle({
    async componentDidMount() {
      await this.props.getStoryBySlug();
    },
    async componentDidUpdate(prevProps) {
      if (this.props.match.params.slug !== prevProps.match.params.slug) {
        await this.props.getStoryBySlug();
      }
    }
  }),
  withLoader,
  branch(({ story }) => !story, renderNothing),
  withHandlers({
    beforeChange:
      ({ setStep, sortedSteps, storyKey, sendContentStatus }) =>
      (oldIndex, newIndex) => {
        if (oldIndex === newIndex) return;

        setStep(newIndex + 1);

        const { pageNumber } = sortedSteps[oldIndex];
        const options = {
          activityKey: TYPE_TO_ACTIVITY_KEY.story,
          activityStatusKey: 'pageRead',
          data: { key: storyKey, storyPageNumber: pageNumber }
        };

        sendContentStatus(options);
      }
  })
)(Component);

StoryTemplate.displayName = 'StoryTemplate';
