import classnames from 'classnames';
import React from 'react';
import { compose, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import { sendQuestionSectionInfo } from '../../store/reducers/user-attributes/actions';
import styles from '../../styles/components/checklist-item.module.scss';
import { FHC_MODULE_STATUSES } from '../../helpers/constants';
import { setNavChecklistLink } from '../../store/reducers/navigation';
import { AffiliateIcon } from '../../components/icons/affiliate-icon';
import { NavLink } from '../../components/link';
import { getStatusPlain, redirectToModule } from '../../helpers/custom-page/is-completed';
import { withDisplayCondition } from './with-display-condition';

const ChecklistItemComponent = React.memo(
  ({ className, isButton, to, iconName, iconStyle, status, item: { labelText, isExternal, link }, handleClick }) => (
    <li className={styles.container} style={iconStyle}>
      <NavLink
        to={to}
        ariaLabel={labelText}
        isExternal={isExternal}
        isBlocked={!link}
        isButton={isButton}
        className={classnames(styles.button, className)}
        activeClassName={styles.selected}
        onClick={handleClick}
      >
        <AffiliateIcon
          name={iconName}
          fallback={{ external: `checklist-indicator-${FHC_MODULE_STATUSES.TO_DO}` }}
          className={styles.status}
        />
        <p className={styles.label}>{labelText}</p>
        <AffiliateIcon name='checklist-action' className={styles.action} />
      </NavLink>
    </li>
  )
);

ChecklistItemComponent.displayName = 'ChecklistItemComponent';

export const ChecklistItem = compose(
  connect(
    state => ({
      selected: state.navigation.checklistLink,
      statusData: state.statusData
    }),
    { setNavChecklistLink, sendQuestionSectionInfo }
  ),
  withProps(({ item, statusData }) => {
    const { link, statusMappingDetails, displayCondition } = item;
    const status = getStatusPlain(statusData.parsedStatuses[statusMappingDetails])?.toLowerCase();

    return {
      to: redirectToModule(link, statusMappingDetails, statusData),
      iconName: `checklist-indicator-${status}`,
      iconStyle: {
        '--app-custom-checklist-item-bg-colour': `var(--checklist-item-checkbox-${status}-bg-colour)`,
        '--app-custom-checklist-item-icon-colour': `var(--checklist-item-checkbox-${status}-icon-colour)`,
        '--app-custom-checklist-item-border': `var(--checklist-item-checkbox-${status}-border)`
      },
      status,
      displayCondition
    };
  }),
  withHandlers({
    handleClick:
      ({ selected, item, setNavChecklistLink, onClick, isButton }) =>
      () => {
        if (selected?.link !== item.link) {
          setNavChecklistLink(item);
        }

        if (isButton && onClick) {
          onClick();
        }
      }
  }),
  withDisplayCondition
)(ChecklistItemComponent);

ChecklistItem.displayName = 'ChecklistItem';
