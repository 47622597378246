import { POST_TYPES } from '../articles/article';

const CONTENT_TYPE_NAME = {
  article: POST_TYPES.ARTICLE,
  blog: POST_TYPES.BLOG,
  announcement: POST_TYPES.ANNOUNCEMENT,
  project: POST_TYPES.PROJECT,
  story: 'story',
  video: 'video'
};

export const TYPE_TO_ACTIVITY_KEY = {
  [CONTENT_TYPE_NAME.article]: 'Article',
  [CONTENT_TYPE_NAME.blog]: 'blog',
  [CONTENT_TYPE_NAME.announcement]: 'blog',
  [CONTENT_TYPE_NAME.project]: 'project',
  [CONTENT_TYPE_NAME.story]: 'story',
  [CONTENT_TYPE_NAME.video]: 'video'
};
