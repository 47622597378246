import React from 'react';
import classnames from 'classnames';
import { compose, withState, withHandlers, defaultProps } from 'recompose';
import { isNil } from '@packages/helpers/core/common';
import styles from '../../styles/components/segmented-button.module.scss';
import { valueToActiveState } from '../../helpers/inputs/segmented-button';
import { withContainer } from '../inputs/input-field';

const Segment = React.memo(({ active, handleClick, item: { label, value, name } }) => {
  // For segmented input we are connecting to `label` attr, as there are cases when the name and values can be the same for different options
  return (
    <button
      className={classnames(styles.segment, { [styles.active]: label === active })}
      onClick={handleClick({ value, name, label })}
    >
      <span className={styles.label}>{label}</span>
    </button>
  );
});

const SegmentedButtonComponent = React.memo(({ items, className, atid, ...props }) => (
  <div className={classnames(styles.container, className)} data-atid={atid}>
    {items.map(item => (
      <Segment key={item.label} item={item} {...props} />
    ))}
  </div>
));

export const SegmentedButton = compose(
  defaultProps({
    items: []
  }),
  withState('active', 'setActive', ({ items, initialValue, isInput, value }) => {
    if (isInput) {
      return valueToActiveState(value);
    }
    return isNil(initialValue) ? items[0] && items[0].value : initialValue;
  }),
  withHandlers({
    handleClick:
      ({ setActive, onChange }) =>
      ({ value, name, label }) =>
      () => {
        setActive(label);
        onChange && onChange(value, name, label);
      }
  })
)(SegmentedButtonComponent);

export const InputSegmentedButton = withContainer(SegmentedButton);
