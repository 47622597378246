export const LAYOUT_SECTIONS = {
  SIDEBAR: 'sidebar',
  TOP_NAVIGATION: 'topNavigation',
  TOP_SECTION_NAVIGATION: 'topSectionNavigation',
  TOP_BACK_BUTTON: 'topBackButton',
  BOTTOM_NAVIGATION: 'bottomNavigation'
};

const EXCLUSION_MARK = '!';

export const splitIsVisibleOptionsEntry = (options = []) => {
  const inclusive = [],
    exclusive = [];

  for (const entry of options) {
    if (entry.startsWith(EXCLUSION_MARK)) {
      exclusive.push(entry);
    } else {
      inclusive.push(entry);
    }
  }

  return {
    inclusive,
    exclusive
  };
};

export const trimExclusionMark = name => name.slice(EXCLUSION_MARK.length);
export const padExclusionMark = name => `${EXCLUSION_MARK}${name}`;
