import { createAction, createReducer } from '../utils';

const GET_STORIES_REQUEST = 'GET_STORIES_REQUEST';
const GET_STORIES_SUCCESS = 'GET_STORIES_SUCCESS';
const GET_STORIES_FAILURE = 'GET_STORIES_FAILURE';

export const getStories = slug =>
  createAction(
    {
      request: GET_STORIES_REQUEST,
      success: GET_STORIES_SUCCESS,
      failure: GET_STORIES_FAILURE
    },
    api => api.stories.getStories
  )(slug);

const initialState = {
  list: {},
  loading: true,
  error: false
};

export const stories = createReducer(initialState, {
  [GET_STORIES_REQUEST]: state => ({ ...state, loading: true }),
  [GET_STORIES_SUCCESS]: (state, action) => ({
    ...state,
    list: { ...state.list, [action.payload.slug]: action.payload },
    loading: false,
    error: false
  }),
  [GET_STORIES_REQUEST]: state => ({ ...state, loading: false, error: true })
});
