import { ConfigService } from '../../services/config-service';
import { createReducer, createAction } from '../utils';

const GET_STATIC_DATA_REQUEST = 'GET_STATIC_DATA_REQUEST';
const GET_STATIC_DATA_SUCCESS = 'GET_STATIC_DATA_SUCCESS';
const GET_STATIC_DATA_FAILURE = 'GET_STATIC_DATA_FAILURE';

export const getStaticData = type => (dispatch, getState) => {
  const loading = getState().staticData.loading;
  if (loading[type]) {
    return;
  }

  return dispatch(
    createAction(
      {
        request: GET_STATIC_DATA_REQUEST,
        success: GET_STATIC_DATA_SUCCESS,
        failure: GET_STATIC_DATA_FAILURE
      },
      api => api.configuration.getDataDictionary
    )(type)
  );
};

export const getAffiliateRelatedStaticData = () => async dispatch => {
  const DICTIONARIES = ConfigService.get('STATIC_DATA.DICTIONARIES', null);

  if (DICTIONARIES) {
    for (const type of DICTIONARIES) {
      await dispatch(getStaticData(type));
    }
  }
};

export const staticData = createReducer(
  { loading: {}, error: {} },
  {
    [GET_STATIC_DATA_REQUEST]: (state, { params }) => ({ ...state, loading: { ...state.loading, [params]: true } }),
    [GET_STATIC_DATA_SUCCESS]: (state, { params, payload }) => ({
      ...state,
      [params]: payload,
      loading: { ...state.loading, [params]: false }
    }),
    [GET_STATIC_DATA_FAILURE]: (state, { params }) => ({
      ...state,
      loading: { ...state.loading, [params]: false },
      error: { ...state.error, [params]: true }
    })
  }
);
