import React from 'react';
import { compose } from 'recompose';
import { SegmentedButton } from '../../../components/buttons/segmented-button';
import { withInputLabel } from '../../../components/inputs/input';
import { withButton, withContainer } from './components/input-field';
import { withPreSubmitButton } from './components/pre-submit-button/with-pre-submit-button';

const Input = compose(withContainer, withInputLabel, withButton, withPreSubmitButton)(SegmentedButton);

export const QuestionSegmentedButton = React.memo(
  ({ value, buttonLabel, items, onChange, onInput, onSubmit, loading, disabled, preSubmitButton, label }) => (
    <Input
      value={value}
      label={label}
      buttonLabel={buttonLabel}
      items={items}
      disabled={disabled}
      onChange={onChange}
      onTextChange={onInput}
      onSubmit={onSubmit}
      loading={loading}
      preSubmitButton={preSubmitButton}
      isInput
    />
  )
);
