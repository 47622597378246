import React from 'react';
import { compose, withStateHandlers } from 'recompose';
import { Column, Grid } from '../../components/layout/grid';
import { ConfigService } from '../../services/config-service';
import { Template } from '../../components/template';
import { Header } from '../../components/layout/page-header';
import { Spacer } from '../../components/layout/spacers';
import { CategoryFilter } from '../../components/layout/category-filter';
import { SegmentedButton } from '../../components/buttons/segmented-button';
import styles from '../../styles/templates/articles-template.module.scss';
import { DEFAULT_CATEGORY_KEY } from '../../helpers/feed/constants';
import { KnowledgeBaseTopic } from './topic';
import { withKnowledgeBase } from './with-knowledge-base';

const tabs = [
  {
    label: 'All',
    value: 'All'
  },
  {
    label: 'Saving',
    value: 'Saving'
  },
  {
    label: 'Hunting',
    value: 'Hunting'
  },
  {
    label: 'Buying',
    value: 'Buying'
  }
];

const KnowledgeBaseComponent = React.memo(
  ({ knowledgeBase, filter, topicFilter, handleFilterChange, handleTopicFilterChange, categoryFilterOptions }) => {
    const {
      pageHeader: { heading, subheading, image, category, type, backgroundPosition },
      TEMPLATE: { filter: withFilter }
    } = ConfigService.get('ARTICLES_HOME_PAGE', {});

    const filteredCategoriesByValue =
      filter === DEFAULT_CATEGORY_KEY
        ? knowledgeBase
        : knowledgeBase.filter(({ category }) => category && category.includes(filter));

    return (
      <>
        <Header
          className={styles.header}
          heading={heading}
          subheading={subheading}
          category={category}
          image={image}
          type={type}
          backgroundPosition={backgroundPosition}
          withKeyline
        />
        <Template className={styles.container}>
          {withFilter.show && (
            <>
              {withFilter.type === 'homebuying-stage' && <Spacer type='top' />}
              <Column edge={['xss', 'lg']}>
                {withFilter.type === 'homebuying-stage' && (
                  <>
                    <Spacer type='component' />
                    <div className='grid'>
                      <SegmentedButton items={tabs} onChange={handleTopicFilterChange} />
                    </div>
                    <Spacer type='component' />
                  </>
                )}
                {withFilter.type === 'category' && (
                  <>
                    <Spacer type='content' />
                    <div className='grid'>
                      <CategoryFilter
                        selectName='knowledgeBase'
                        values={categoryFilterOptions}
                        filterTitle={withFilter.filterTitle}
                        handleFilterChange={handleFilterChange}
                      />
                    </div>
                  </>
                )}
              </Column>
            </>
          )}
          <Column edge>
            {filteredCategoriesByValue.map(topic => (
              <Grid className={styles.section} key={topic.id}>
                <Column>
                  <KnowledgeBaseTopic key={topic.id} topicFilter={topicFilter} {...topic} />
                </Column>
              </Grid>
            ))}
          </Column>
          <Spacer type='bottom' />
        </Template>
      </>
    );
  }
);

export const KnowledgeBase = compose(
  withStateHandlers(({ filter = 'All', topicFilter = 'All' }) => ({ filter, topicFilter }), {
    handleFilterChange: () => value => ({ filter: value }),
    handleTopicFilterChange: () => value => ({ topicFilter: value })
  }),
  withKnowledgeBase
)(KnowledgeBaseComponent);
